import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import ApiURl from '../controllers/Api';
import usericon from '../images/usericon.jpg';

const ProfileSection = () => {
  const [userData, setUserData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      $.ajax({
        url: `${ApiURl}/getUser.php`,
        method: 'GET',
        data: { token },
      })
        .done((response) => {
          if (response.success) {
            setUserData(response.ver_user);
          }
        })
        .fail((error) => {
          console.error('Error fetching user data:', error);
        });
    }
  }, []);

  return (
    <div className="bg-gradient-to-b from-blue-50 to-white p-6 rounded-lg shadow-xl mt-5 mx-3 md:mx-6">
      <h2 className="text-3xl font-bold text-blue-600 mb-6 text-center">Profile</h2>

      <div className="flex flex-col md:flex-row items-center md:items-start md:space-x-8">
        <div className="relative">
          <img
            src={usericon}
            alt="Profile"
            className="w-36 h-36 rounded-full object-cover shadow-lg border-4 border-blue-500"
          />
          {/* <div className="absolute bottom-0 right-0 bg-blue-500 p-2 rounded-full shadow-md">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-white"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 4v16m8-8H4"
              />
            </svg>
          </div> */}
        </div>

        <div className="mt-6 md:mt-0">
          <h3 className="text-2xl font-bold text-gray-800">
            {userData.firstname} {userData.lastname}
          </h3>
          <p className="text-gray-600">{userData.email}</p>
          {/* <button className="mt-6 bg-blue-500 text-white px-6 py-2 rounded-md shadow-md transition-all duration-300 transform hover:bg-blue-600 hover:scale-105">
            Edit Profile
          </button> */}
        </div>
      </div>

      <div className="mt-10 grid grid-cols-1 sm:grid-cols-2 gap-6">
        {[
          { label: 'Username', value: userData.username },
          { label: 'First Name', value: userData.firstname },
          { label: 'Last Name', value: userData.lastname },
          { label: 'Phone Number', value: `+91 - ${userData.phone}` },
          { label: 'Email', value: userData.email },
          { label: 'Member Since', value: userData.created_at },
        ].map(({ label, value }, index) => (
          <div
            key={index}
            className="bg-gray-100 p-4 rounded-lg shadow-md transition-all duration-300 hover:shadow-lg hover:bg-blue-50"
          >
            <h4 className="text-gray-700 font-semibold">{label}</h4>
            <p className="text-gray-600 mt-1">{value}</p>
          </div>
        ))}
      </div>

      <div className="mt-10">
        <button
          onClick={() => navigate('/ForgetPassword')}
          className="bg-gradient-to-r from-blue-400 to-blue-500 text-white text-sm px-6 py-3 rounded-md shadow-lg w-full md:w-auto hover:from-blue-500 hover:to-blue-600 transform transition-all duration-300 hover:scale-105"
        >
          Change Password
        </button>
      </div>
    </div>
  );
};

export default ProfileSection;