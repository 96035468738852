import React, { useEffect, useState } from "react";
import { useTable } from "react-table";
import $ from "jquery";
import ApiURl from "../../controllers/Api";

const ManageMainCategory = () => {
  const [catData, setCatData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await $.getJSON(`${ApiURl}/getallmaincat.php`);
        if (response && response.data) {
          const dataWithMainCategoryNames = response.data.map((category) => ({
            ...category,
            main_category_name: category.parent_id
              ? category.main_category_name || "N/A"
              : "Root",
          }));
          setCatData(dataWithMainCategoryNames);
        } else {
          console.error("Invalid response format:", response);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleToggleStatus = async (id, currentStatus) => {
    const newStatus = currentStatus === 1 ? 0 : 1;
    const action = newStatus === 1 ? "activate" : "deactivate";

    if (
      window.confirm(
        `Are you sure you want to ${action} this category (ID: ${id})?`
      )
    ) {
      // Optimistically update the UI
      setCatData((prevData) =>
        prevData.map((cat) =>
          cat.id === id ? { ...cat, is_active: newStatus } : cat
        )
      );

      try {
        const response = await $.ajax({
          url: `${ApiURl}/updateCategoryStatus.php`,
          type: "POST",
          data: { id, is_active: newStatus },
        });

        const result = JSON.parse(response);
        if (result.status === "success") {
          alert(result.message);
        } else {
          throw new Error(result.message);
        }
      } catch (error) {
        // Revert the UI update if the request fails
        setCatData((prevData) =>
          prevData.map((cat) =>
            cat.id === id ? { ...cat, is_active: currentStatus } : cat
          )
        );
        alert("Failed to update the category. Please try again.");
        console.error("AJAX request failed:", error);
      }
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "S.NO",
        accessor: "id",
      },
      {
        Header: "Main Category Name",
        accessor: "category_name",
      },
      {
        Header: "Created On",
        accessor: "created_by",
      },
      {
        Header: "Status",
        accessor: "is_active",
        Cell: ({ value }) => (value === 0 ? "Inactive" : "Active"),
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ row }) => (
          <button
            type="button"
            onClick={() =>
              handleToggleStatus(row.original.id, row.original.is_active)
            }
            className={`focus:outline-none text-sm px-2 mb-2 transition-colors duration-500 ${
              row.original.is_active
                ? "bg-red-600 hover:bg-red-700 text-white"
                : "bg-green-600 hover:bg-green-700 text-white"
            }`}
          >
            {row.original.is_active ? "Deactivate" : "Activate"}
          </button>
        ),
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: catData,
    });

  return (
    <div className="overflow-x-auto shadow-md sm:rounded-md bg-gray-100 md:px-7 mt-5 mx-auto border-y border-gray-400 md:w-4/5">
      <table
        {...getTableProps()}
        className="w-full text-sm whitespace-nowrap text-left text-gray-500"
      >
        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()} className="px-6 py-3">
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                className={`bg-white border-b hover:bg-gray-50 ${
                  row.original.is_active ? "" : "bg-gray-200 text-gray-500"
                }`}
              >
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()} className="px-6 py-4">
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ManageMainCategory;
