import React from 'react';
import { useNavigate } from 'react-router-dom';
import screenshot from '../images/error.png';

const EmptyCart = () => {
  const navigate = useNavigate();

  const redirectToHome = () => {
    navigate('/'); // Adjust the path to your homepage
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-[#FFD4FF] p-4">
      <img
        src={screenshot}
        alt="Empty cart illustration"
        className="w-full max-w-[500px] object-contain h-auto md:max-w-[800px]" 
      />
      <button 
        onClick={redirectToHome} 
        className="mt-6 px-6 py-3 bg-blue-500 text-white text-lg font-semibold rounded-md hover:bg-blue-700"
      >
        Return back to shopping 
      </button>
    </div>
  );
};

export default EmptyCart;
