import React, { useState, useEffect } from "react";
import $ from "jquery";
import ApiURl from "../controllers/Api";
import { useNavigate } from "react-router-dom";

const DashboardSection = () => {
  const [data, setData] = useState({
    totalOrders: 0,
    totalSpend: 0,
    pendingOrders: 0,
    recentOrders: [],
  });
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
   const navigate = useNavigate();
  

  useEffect(() => {
    // Function to fetch user data
    const fetchUserData = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        $.ajax({
          url: `${ApiURl}/getUser.php`,
          method: "GET",
          data: { token },
        })
          .done((response) => {
            if (response.success) {
              setUserData(response.ver_user);
            }
          })
          .fail((error) => {
            console.error("Error fetching user data:", error);
          });
      }
    };

    // Function to fetch dashboard data
    const fetchDashboardData = async () => {
      try {
        setLoading(true);

        const userId = localStorage.getItem("user_id");
        if (!userId) {
          setError("User not logged in.");
          return;
        }

        const response = await fetch(
          `${ApiURl}/getUserDataDashboard.php?user_id=${userId}`
        );
        const result = await response.json();

        if (result.success) {
          setData({
            totalOrders: result.data.totalOrders || 0,
            totalSpend: result.data.totalSpend || 0,
            pendingOrders: result.data.pendingOrders || 0,
            recentOrders: result.data.recentOrders || [],
          });
        } else {
          setError(result.message || "Failed to fetch data.");
        }
      } catch (err) {
        setError("An error occurred while fetching data.");
      } finally {
        setLoading(false);
      }
    };

    // Fetch user data and dashboard data when the component mounts
    fetchUserData();
    fetchDashboardData();

  }, []); // Empty dependency array ensures this only runs once when the component mounts

  // If loading, show loading indicator
  if (loading) {
    return <div>Loading...</div>;
  }

  // If there is an error, show the error message
  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  // Render the dashboard content
  return (
    <div className="bg-gray-50 md:mx-6 mx-3 mt-5">
      {/* Welcome Section */}
      <div className="mb-8">
        <h1 className="text-4xl font-bold text-gray-800">
          Hello, {userData.firstname || "User"}!
        </h1>
        <p className="text-lg text-gray-600 mt-2">Welcome back to your dashboard.</p>
      </div>

      {/* Statistics Section */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        {[ 
          { label: "Total Orders", value: data.totalOrders, color: "bg-[#E8CBA7]" },
          { label: "Total Spend", value: `₹${data.totalSpend}`, color: "bg-[#E8CBA7]" },
          { label: "Pending Orders", value: data.pendingOrders, color: "bg-[#E8CBA7]" },
        ].map((stat, index) => ( 
          <div key={index} className={`${stat.color} text-black p-6 rounded-lg shadow-lg`}>
            <h3 className="text-lg font-semibold">{stat.label}</h3>
            <p className="mt-2 text-3xl font-bold">{stat.value}</p>
          </div>
        ))}
      </div>

      {/* Recent Orders Section */}
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h3 className="text-xl font-semibold text-gray-700 mb-4">Recent Orders</h3>
        <ul className="space-y-4">
          {data.recentOrders.length > 0 ? (
            data.recentOrders.map((order) => (
              <li
                key={order.order_id}
                className="flex justify-between items-center border-b pb-3 last:border-b-0"
              >
                <div className="flex-1">
                  <h4 className="font-semibold text-gray-800">Order #{order.order_id}</h4>
                  <p className="text-gray-600">Order Date: {order.date}</p>
                </div>
                <p className="text-gray-800 font-semibold">&#x20b9;{order.amount}</p>
                <button
                  className="bg-[#DD9045] hover:bg-[#C77A2B] text-white py-2 px-4 rounded-md ml-4 text-sm"
                  onClick={() => navigate(`/Receipt/${order.order_id}`)}
                >
                  View Recipet
                </button>
              </li>
            ))
          ) : (
            <p className="text-gray-600">No recent orders available.</p>
          )}
        </ul>

        {/* View All Orders Button */}
        <div className="mt-6 text-center">
          <button
            className="bg-black text-[#DD9045]  px-6 py-2 rounded-lg shadow-md"
            onClick={() => navigate('/order-section')}
          >
            View All Orders
          </button>
        </div>
      </div>
    </div>
  );
};

export default DashboardSection;
