import React, { useState, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel styles
import backgrounde from "../images/background.png";
import b1imagee from "../images/b1image.png";
import b2imagee from "../images/b2image.png";
import b3imagee from "../images/b3image.png";
import b4imagee from "../images/b4image.png";
import b5imagee from "../images/b5image.png";
import mobileBackground from "../images/mobileBackground.png"

function Sidebar() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const carouselContent = [
    {
      title: "FRIDGE MAT",
      description:
        "We are presenting a widespread variety of different colors. Fridge Mat is fabricated in accordance with present market trends. It uses superior quality fabric and progressive technology.",
      image: b1imagee,
    },
    {
      title: "TABLE COVER",
      description:
        "We are presenting a widespread variety of different colors. Fridge Mat is fabricated in accordance with present market trends. It uses superior quality fabric and progressive technology.",
      image: b2imagee,
    },
    {
      title: "MATTRESS COVER",
      description:
        "We are presenting a widespread variety of different colors. Fridge Mat is fabricated in accordance with present market trends. It uses superior quality fabric and progressive technology.",
      image: b4imagee,
    },
    {
      title: "APRON",
      description:
        "We are presenting a widespread variety of different colors. Fridge Mat is fabricated in accordance with present market trends. It uses superior quality fabric and progressive technology.",
      image: b3imagee,
    },
    {
      title: "WASHING MACHINE COVER",
      description:
        "We are presenting a widespread variety of different colors. Fridge Mat is fabricated in accordance with present market trends. It uses superior quality fabric and progressive technology.",
      image: b5imagee,
    },
  ];

  // Auto-slide logic
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % carouselContent.length);
    }, 7000); // Change content every 7 seconds

    return () => clearInterval(interval);
  }, [carouselContent.length]);

  return (
    <div
      className="relative w-full h-[65vh] sm:h-[50vh] lg:h-[50vh] overflow-hidden pt-16 bg-center bg-cover bg-no-repeat"
      style={{
        backgroundImage: `url(${backgrounde})`, // Default background (for larger screens)
      }}
    >
      {/* Add Tailwind classes for the mobile background */}
      <div
        className="absolute inset-0 w-full h-full bg-black bg-opacity:30 bg-cover bg-center bg-no-repeat sm:hidden"
        style={{ backgroundImage: `url(${mobileBackground})` }}
      />
      {/* Carousel Container */}
      <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-0 flex items-center justify-center px-5 md:px-20 lg:px-40">
        <Carousel
          selectedItem={currentIndex}
          autoPlay
          infiniteLoop
          showArrows={false}
          showThumbs={false}
          showStatus={false}
          onChange={(index) => setCurrentIndex(index)}
        >
          {carouselContent.map((content, index) => (
            <div
              key={index}
              className="flex flex-col sm:flex-row items-center text-black max-w-full px-4 sm:px-8 md:px-[20px]"
            >
              {/* Image Section */}
              <div className="w-full sm:w-1/2 mb-4 sm:mb-0 flex justify-center">
                <img
                  src={content.image}
                  alt={content.title}
                  className={`rounded-lg shadow-xl max-w-[300px] h-auto object-cover ${
                    content.title === "WASHING MACHINE COVER" ? "max-w-[250px]" : ""
                  }`}
                />
              </div>

              {/* Text Section */}
              <div className="w-full sm:w-1/2 flex flex-col items-center sm:items-start mt-8 sm:mt-0 md:px-[-20px]">
                <h1 className="text-3xl sm:text-4xl lg:text-5xl font-extrabold mb-4 text-center sm:text-left">
                  {content.title}
                </h1>
                <div className="text-sm sm:text-base leading-snug space-y-1 text-center sm:text-left">
                  {content.description
                    .split(".")
                    .map((line, idx) =>
                      line.trim() ? <p key={idx}>{line}.</p> : null
                    )}
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
}

export default Sidebar;
