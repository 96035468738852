import React, { useEffect, useState } from "react";
import Category from "./category";
import $ from "jquery";
import Slider from "./Slider";
import { Link } from "react-router-dom";
import Layout from "../pages/Layout";
import ApiURl from "../controllers/Api";
import imgLocation from "../controllers/imagePath";
import AboutUs from "./AboutUs";

function Home() {
  const [catData, setCatData] = useState([]);
  const [animationClass, setAnimationClass] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${ApiURl}/getMainCategory.php`);
        const data = await response.json();
        console.log(data);
        if (data && data.data) {
          // Filter categories with is_active = 1
          const activeCategories = data.data.filter(
            (category) => category.is_active === "1"
          );
          setCatData(activeCategories);
        } else {
          console.error("Unexpected API response structure:", data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();

    // Trigger animation on component mount
    const timeout = setTimeout(() => {
      setAnimationClass("animate-fade-up");
    }, 100); // Delay for smoother entry

    return () => clearTimeout(timeout);
  }, []);

  // Scroll to the top whenever this component is rendered
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="bg-gradient-to-r from-[#E5AA70] via-[#E5AA70] to-[#E5AA70]">
      <Slider />

      <div className="mt-10">
        <h1 className="md:text-3xl text-xl text-center font-bold mx-10 border-y-2 border-gray-600 py-2 md:py-3">
          Products Category
        </h1>
      </div>
      <div className="flex flex-wrap justify-center gap-x-8 mx-10">
        {catData.map((row) => {
          return (
            <Link
              to={`/Products/${row.id}`}
              key={row.id}
              className="w-full sm:w-1/2 md:w-80" // Added responsive classes here
              onClick={() => window.scrollTo(0, 0)}
            >
              <Category
                img={`${imgLocation}/${row.sample_image}`}
                productsName={row.category_name}
              />
            </Link>
          );
        })}
      </div>

      <div className="px-4 py-10 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="grid grid-cols-2 row-gap-8 md:grid-cols-4">
          <div
            className={`text-center md:border-r mb-4 transition duration-1000 ${animationClass}`}
          >
            <h6 className="text-4xl font-bold lg:text-5xl xl:text-6xl">10+</h6>
            <p className="text-sm font-medium tracking-widest text-gray-800 uppercase lg:text-base">
              Year Experience
            </p>
          </div>
          <div
            className={`text-center md:border-r mb-4 transition duration-1000 delay-100 ${animationClass}`}
          >
            <h6 className="text-4xl font-bold lg:text-5xl xl:text-6xl">100+</h6>
            <p className="text-sm font-medium tracking-widest text-gray-800 uppercase lg:text-base">
              Products
            </p>
          </div>
          <div
            className={`text-center md:border-r mb-4 transition duration-1000 delay-200 ${animationClass}`}
          >
            <h6 className="text-4xl font-bold lg:text-5xl xl:text-6xl">1K+</h6>
            <p className="text-sm font-medium tracking-widest text-gray-800 uppercase lg:text-base">
              Happy Customer
            </p>
          </div>
          <div
            className={`text-center transition duration-1000 delay-300 ${animationClass}`}
          >
            <h6 className="text-4xl font-bold lg:text-5xl xl:text-6xl">10+</h6>
            <p className="text-sm font-medium tracking-widest text-gray-800 uppercase lg:text-base">
              Category
            </p>
          </div>
        </div>
      </div>

      <div className="w-full sm:w-3/4 md:w-1/2 lg:w-1/3 h-1 bg-gray-800 mx-auto"></div>

      <AboutUs />
    </div>
  );
}

export default Home;
