import React, { useState, useEffect } from "react";
import { useTable } from "react-table";
import { RiDeleteBinLine } from "react-icons/ri";
import $ from "jquery";
import ApiURl from "../../controllers/Api";

const ProductList = () => {
  const [productList, setProductList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [editProduct, setEditProduct] = useState(null); // To store the product being edited
  const [newName, setNewName] = useState("");
  const [newPrice, setNewPrice] = useState(""); // Add state for price
  const [newImage, setNewImage] = useState(null);

  // Fetch products on component mount
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setLoading(true);
        const data = await $.getJSON(`${ApiURl}/getProducts.php`);
        if (data.success) {
          setProductList(data.products || []);
        } else {
          throw new Error("Failed to fetch products.");
        }
      } catch (err) {
        console.error("Error fetching products:", err);
        setError(err.message);
        setProductList([]);
      } finally {
        setLoading(false);
      }
    };
    fetchProducts();
  }, []);

  // Handle product deletion
  const handleDelete = async (productId) => {
    if (window.confirm("Are you sure you want to delete this product?")) {
      try {
        const response = await fetch(`${ApiURl}/deleteProduct.php`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ product_id: productId }),
        });
        const result = await response.json();
        if (result.success) {
          setProductList((prevProducts) =>
            prevProducts.filter((product) => product.product_id !== productId)
          );
          alert(result.message);
        } else {
          alert(result.message || "Failed to delete product.");
        }
      } catch (error) {
        console.error("Error deleting product:", error);
        alert("An error occurred while deleting the product.");
      }
    }
  };

  // Handle availability toggle for individual sizes
  const handleAvailabilityToggle = async (productId, size, available) => {
    try {
        const response = await fetch(`${ApiURl}/updateProduct.php`, {
            method: "POST",
            headers: { 
                "Content-Type": "application/json" 
            },
            body: JSON.stringify({
                product_id: productId,  // The ID of the product
                size: size,             // The size of the product
                available: available    // The availability status (0 or 1)
            }),
        });

        // Check if the response is successful
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();

        if (result.success) {
            setProductList(prevProducts =>
                prevProducts.map(product =>
                    product.product_id === productId && product.size === size
                        ? { ...product, is_available: result.data?.is_available ?? product.is_available }
                        : product
                )
            );
            alert(result.message);
        } else {
            alert(result.message || "Failed to update availability.");
        }
    } catch (error) {
        console.error('Error toggling availability:', error);
        alert("An error occurred while updating the product availability.");
    }
};

 // Handle Edit button click
const handleEdit = (product) => {
  setEditProduct(product);
  setNewName(product.name);
  setNewPrice(product.price); // Set the price when editing a product
  setNewImage(product.image); // Keep the previous image, don't reset to null
};

const handleSaveEdit = async () => {
  if (!newName || !newPrice || !editProduct.size) {
    alert("Please enter the product name, price, and select the size.");
    return;
  }

  // Ensure price is a valid number
  const parsedPrice = parseFloat(newPrice);
  if (isNaN(parsedPrice) || parsedPrice <= 0) {
    alert("Please enter a valid price.");
    return;
  }

  // Prepare form data
  const formData = new FormData();
  formData.append("product_id", editProduct.product_id); // Ensure this is set
  formData.append("name", newName); // Ensure this is set
  formData.append("price", parsedPrice); // Append price
  formData.append("size", editProduct.size); // Ensure size is appended

  // Only append the image if it's a valid file and changed
  if (newImage && newImage instanceof File) {
    formData.append("image", newImage); // Append new image
  } else if (!newImage && editProduct.image) {
    formData.append("image", editProduct.image); // Use the previous image if no new image is selected
  }

  try {
    const response = await fetch(`${ApiURl}/updateProductDetails.php`, {
      method: "POST",
      body: formData,
    });

    const result = await response.json();
    if (result.success) {
      setProductList((prevProducts) =>
        prevProducts.map((product) =>
          product.product_id === editProduct.product_id && product.size === editProduct.size
            ? {
                ...product,
                name: newName,
                price: parsedPrice, // Update price in the list
                image: result.data?.img_path || product.image, // Update image URL in the list
              }
            : product
        )
      );
      console.log(result);
      alert(result.message);
      setEditProduct(null); // Close the edit modal/form
    } else {
      alert(result.message || "Failed to update product.");
    }
  } catch (error) {
    console.error("Error updating product:", error);
    alert("An error occurred while updating the product.");
  }
};



  // Table columns definition
  const columns = React.useMemo(
    () => [
      { Header: "Product Name", accessor: "name" },
      { Header: "Size", accessor: "size" },
      { Header: "Amount", accessor: "price" },
      {
        Header: "Item Available",
        accessor: "is_available",
        Cell: ({ row }) => (
          <div>
            <input
              type="checkbox"
              checked={row.original.is_available === 1}
              onChange={() =>
                handleAvailabilityToggle(
                  row.original.product_id,
                  row.original.size,
                  row.original.is_available === 1 ? 0 : 1
                )
              }
            />{" "}
            Available
          </div>
        ),
      },
      { Header: "Category ID", accessor: "category_id" },
      { Header: "Created date", accessor: "created_at" },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ row }) => (
          <div className="action-buttons">
            {/* Edit Button */}
            <button
              type="button"
              className="bg-blue-600 text-white text-bold py-2 px-4 rounded-md mr-2 hover:bg-blue-700"
              onClick={() => handleEdit(row.original)}
            >
              Edit
            </button>
            {/* Delete Button */}
            <button
              type="button"
              className="bg-red-600 text-white text-bold py-2 px-4 rounded-md hover:bg-red-700"
              onClick={() => handleDelete(row.original.product_id)}
            >
              Delete
            </button>
          </div>
        ),
      },
    ],
    []
  );

  // React Table hook
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: productList,
    });

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <div>
      {/* Desktop Layout */}
      <div className=" hidden sm:block overflow-x-auto sm:px-4 mt-5 w-full">
        <table
          {...getTableProps()}
          className="w-full text-sm whitespace-nowrap text-left text-black"
        >
          <thead className="text-sm text-gray-700 uppercase bg-gray-50">
            {headerGroups.map((headerGroup, index) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                key={`headerGroup-${index}`}
              >
                {headerGroup.headers.map((column, colIndex) => (
                  <th
                    {...column.getHeaderProps()}
                    key={`column-${colIndex}`}
                    className="px-3 py-2"
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row, rowIndex) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    key={`row-${rowIndex}`}
                    className="bg-white border-b"
                  >
                    {row.cells.map((cell, cellIndex) => (
                      <td
                        {...cell.getCellProps()}
                        key={`cell-${cellIndex}`}
                        className="px-3 py-2"
                      >
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={columns.length} className="px-3 py-2 text-center">
                  No products available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Mobile Layout */}
      <div className="sm:hidden">
        {productList.map((product, index) => (
          <div key={`product-${index}`} className="bg-white border-b p-4">
            <table className="w-full">
              <tbody>
                <tr>
                  <td className="font-bold">Name:</td>
                  <td>{product.name}</td>
                </tr>
                <tr>
                  <td className="font-bold">Size:</td>
                  <td>{product.size}</td>
                </tr>
                <tr>
                  <td className="font-bold">Amount:</td>
                  <td>{product.price}</td>
                </tr>
                <tr>
                  <td className="font-bold">Created At:</td>
                  <td>{product.created_at}</td>
                </tr>
              </tbody>
            </table>
            <div className="flex items-center justify-between mt-4">
              <div>
                <input
                  type="checkbox"
                  checked={product.is_available === 1}
                  onChange={() =>
                    handleAvailabilityToggle(
                      product.product_id,
                      product.size,
                      product.is_available === 1 ? 0 : 1
                    )
                  }
                />
                Available
              </div>
              <div>
                <button
                  type="button"
                  className="bg-blue-600 text-white py-2 px-4 rounded-md mr-2"
                  onClick={() => handleEdit(product)}
                >
                  Edit
                </button>
                <button
                  type="button"
                  className="bg-red-600 text-white py-2 px-4 rounded-md"
                  onClick={() => handleDelete(product.product_id)}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
      
      {/* Edit Product Modal/Form */}
      {editProduct && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
          <div className="w-full max-w-md bg-white p-6 rounded-md">
            <h2 className="text-xl mb-4">Edit Product</h2>
            <label>
              Product Name:
              <input
                type="text"
                value={newName}
                onChange={(e) => setNewName(e.target.value)}
                className="border p-2 mt-1 w-full"
              />
            </label>
            <label className="mt-4">
              Product Price:
              <input
                type="number"
                value={newPrice}
                onChange={(e) => setNewPrice(e.target.value)}
                className="border p-2 mt-1 w-full"
              />
            </label>
            <label className="mt-4">
              Product Image:
              <input
                type="file"
                onChange={(e) => setNewImage(e.target.files[0])}
                className="border p-2 mt-1 w-full"
              />
            </label>
            <div className="mt-4 flex justify-end space-x-4">
              <button
                onClick={() => setEditProduct(null)}
                className="bg-gray-400 text-white py-2 px-4 rounded-md"
              >
                Cancel
              </button>
              <button
                onClick={handleSaveEdit}
                className="bg-blue-600 text-white py-2 px-4 rounded-md"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductList;
