import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ApiURl from "../../controllers/Api";
import logo from "../../images/logo.png";

const Receipt = () => {
  const { order_id } = useParams(); // Get the order ID from the URL
  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const user_id = localStorage.getItem("user_id"); // Retrieve user_id from localStorage

        if (!user_id) {
          setError("User ID is missing. Please log in.");
          setLoading(false);
          return;
        }

        const response = await fetch(
          `${ApiURl}/getOrderDetails.php?order_id=${order_id}&user_id=${user_id}`
        );

        if (!response.ok) {
          throw new Error("Failed to fetch order details");
        }

        const data = await response.json();
        if (data.success) {
          setOrderDetails(data.data[0]); // Assuming the first object is the relevant order
        } else {
          throw new Error(data.message || "No data available");
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchOrderDetails();
  }, [order_id]);

  const handleDownload = () => {
    const receiptContent = document.getElementById("receipt-content").innerHTML;
    const blob = new Blob([receiptContent], { type: "text/html" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `Receipt_${order_id}.html`;
    link.click();
  };

  const handlePrint = () => {
    const user_id = localStorage.getItem("user_id");

    if (!user_id) {
      setError("User ID is missing. Please log in.");
      return;
    }

    // Open the download_invoice.php page with the necessary query parameters in a new tab
    window.open(
      `${ApiURl}/download_invoice.php?invoice_number=${order_id}`,
      "_blank"
    );
  };

  if (loading) {
    return <p>Loading receipt details...</p>;
  }

  if (error) {
    return <p className="text-red-500">Error: {error}</p>;
  }

  if (!orderDetails) {
    return <p>No receipt data available.</p>;
  }

  const {
    order_id: orderId,
    date,
    status,
    total_amount: totalAmount,
    payment_status: paymentStatus,
    payment_method: paymentMethod,
    address_line1,
    address_line2,
    landmark,
    products,
  } = orderDetails;

  const grandTotal = products.reduce(
    (sum, product) => sum + product.grandtotal,
    0
  );

  return (
    <div className="max-w-full sm:max-w-4xl mx-auto bg-white shadow-lg rounded-lg p-6 my-10 border-t">
      <div id="receipt-content" className="hidden sm:block">
        <div className="text-center">
          <h1 className="text-2xl">Tax invoice</h1>
          <h1 className="w-28 mx-auto mb-4">
            <img src={logo} alt="Bhatia Emporium logo" />
          </h1>
          <p className="text-gray-600 text-lg font-semibold">
            Bhatia Emporium Receipt
          </p>
          <p className="text-sm text-gray-500">
            Thank you for shopping with us!
          </p>
        </div>

        {/* Order Details */}
        <div className="mt-6">
          <h2 className="text-lg font-semibold text-gray-800">Order Details</h2>
          <div className="border-t border-gray-300 pt-4 mb-6">
            <p className="flex justify-between text-sm font-medium text-gray-700">
              <span>Order ID:</span>
              <span className="font-medium">#{orderId}</span>
            </p>
            <p className="flex justify-between text-sm font-medium text-gray-700">
              <span>Date:</span>
              <span>{new Date(date).toLocaleDateString()}</span>
            </p>
            <p className="flex justify-between text-sm font-medium text-gray-700">
              <span>Status:</span>
              <span>{status}</span>
            </p>
            <p className="flex justify-between text-sm font-medium text-gray-700">
              <span>Payment Status:</span>
              <span>{paymentStatus}</span>
            </p>
            <p className="flex justify-between text-sm font-medium text-gray-700">
              <span>Payment Method:</span>
              <span>{paymentMethod}</span>
            </p>
            <p className="flex justify-between text-sm font-medium text-gray-700">
              <span>Shipping Address:</span>
              <span>
                {address_line1}, {address_line2}, {landmark}
              </span>
            </p>
          </div>
        </div>

        {/* Products Table */}
        <div>
          <h2 className="text-lg font-semibold text-gray-800">Products</h2>
          <table className="w-full mt-4 border border-gray-300">
            <thead>
              <tr className="bg-[#dcbbfe] text-white">
                <th className="border border-gray-300 p-2 text-left">
                  Product Name
                </th>
                <th className="border border-gray-300 p-2 text-center">
                  Quantity
                </th>
                <th className="border border-gray-300 p-2 text-center">Size</th>
                <th className="border border-gray-300 p-2 text-right">Price</th>
                <th className="border border-gray-300 p-2 text-right">Total</th>
                <th className="border border-gray-300 p-2 text-right">IGST</th>
                <th className="border border-gray-300 p-2 text-right">SGST</th>
                <th className="border border-gray-300 p-2 text-right">CGST</th>
                <th className="border border-gray-300 p-2 text-right">
                  Grand Total
                </th>
              </tr>
            </thead>
            <tbody>
              {products && products.length > 0 ? (
                products.map((product, index) => (
                  <tr
                    key={index}
                    className={`${
                      index % 2 === 0 ? "bg-gray-100" : "bg-white"
                    } hover:bg-gray-200`}
                  >
                    <td className="border border-gray-300 p-2">
                      {product.name}
                    </td>
                    <td className="border border-gray-300 p-2 text-center">
                      {product.quantity}
                    </td>
                    <td className="border border-gray-300 p-2 text-center">
                      {product.size}
                    </td>
                    <td className="border border-gray-300 p-2 text-right">
                      ₹{product.price.toFixed(2)}
                    </td>
                    <td className="border border-gray-300 p-2 text-right">
                      ₹{product.total_amount.toFixed(2)}
                    </td>
                    <td className="border border-gray-300 p-2 text-right">
                      ₹{product.igst.toFixed(2)}
                    </td>
                    <td className="border border-gray-300 p-2 text-right">
                      ₹{product.sgst.toFixed(2)}
                    </td>
                    <td className="border border-gray-300 p-2 text-right">
                      ₹{product.cgst.toFixed(2)}
                    </td>
                    <td className="border border-gray-300 p-2 text-right">
                      ₹{product.grandtotal.toFixed(2)}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan="9"
                    className="border border-gray-300 p-2 text-center text-gray-500"
                  >
                    No products available.
                  </td>
                </tr>
              )}
            </tbody>
            {/* Grand Total Row */}
            <tfoot>
              <tr className="bg-gray-300">
                <td
                  colSpan="8"
                  className="text-right font-semibold p-2 border-t border-gray-400"
                >
                  Total Grand Amount:
                </td>
                <td className="text-right font-semibold p-2 border-t border-gray-400">
                  ₹{grandTotal.toFixed(2)}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      {/* Mobile adjustments */}
      <div id="mobile-section" className="block sm:hidden">
        <div className="text-center">
          <h1 className="text-2xl">Tax invoice</h1>
          <h1 className="w-28 mx-auto mb-4">
            <img src={logo} alt="Bhatia Emporium logo" />
          </h1>
          <p className="text-gray-600 text-lg font-semibold">
            Bhatia Emporium Receipt
          </p>
        </div>

        {/* Order Details */}
        <div className="mt-6 px-4">
          <h2 className="text-lg font-semibold text-gray-800 mb-4">
            Order Details
          </h2>
          <div className="border-t border-gray-300 pt-3">
            <p className="flex justify-between text-sm font-medium text-gray-700 mb-2">
              <span className="truncate">Order ID:</span>
              <span className="font-medium truncate">#{orderId}</span>
            </p>
            <p className="flex justify-between text-sm font-medium text-gray-700 mb-2">
              <span className="truncate">Date:</span>
              <span>{new Date(date).toLocaleDateString()}</span>
            </p>
            <p className="flex justify-between text-sm font-medium text-gray-700 mb-2">
              <span className="truncate">Status:</span>
              <span>{status}</span>
            </p>
            <p className="flex justify-between text-sm font-medium text-gray-700 mb-2">
              <span className="truncate">Payment Status:</span>
              <span>{paymentStatus}</span>
            </p>
            <p className="flex justify-between text-sm font-medium text-gray-700 mb-2">
              <span className="truncate">Payment Method:</span>
              <span>{paymentMethod}</span>
            </p>
            <p className="flex flex-col text-sm font-medium text-gray-700">
              <span className="truncate mb-1">Shipping Address:</span>
              <span className="text-right">
                {address_line1}, {address_line2}, {landmark}
              </span>
            </p>
          </div>
        </div>

        {/* Products Table */}
        <div>
          <h2 className="text-lg text-gray-800">Products</h2>
          <div className="overflow-x-auto mt-4">
            <table className="min-w-full border border-gray-300">
              <thead>
                <tr className="bg-[#dcbbfe] text-white">
                  <th className="border border-gray-300 p-1 text-left text-xs leading-5 w-[15%]">
                    Product
                  </th>
                  <th className="border border-gray-300 p-1 text-center text-xs leading-5 w-[10%]">
                    Qty
                  </th>
                  <th className="border border-gray-300 p-1 text-center text-xs leading-5 w-[10%]">
                    Size
                  </th>
                  <th className="border border-gray-300 p-1 text-right text-xs leading-5 w-[20%]">
                    Price
                  </th>
                  <th className="border border-gray-300 p-1 text-right text-xs leading-5 w-[20%]">
                    Payout
                  </th>
                </tr>
              </thead>
              <tbody>
                {products && products.length > 0 ? (
                  products.map((product, index) => (
                    <tr
                      key={index}
                      className={`${
                        index % 2 === 0 ? "bg-gray-100" : "bg-white"
                      } hover:bg-gray-200`}
                    >
                      <td className="border border-gray-300 p-1 text-xs font-semibold leading-5 w-[15%] break-words">
                        {product.name}
                      </td>
                      <td className="border border-gray-300 p-1 text-center text-xs font-semibold w-[10%]">
                        {product.quantity}
                      </td>
                      <td className="border border-gray-300 p-1 text-center text-xs font-semibold w-[10%]">
                        {product.size}
                      </td>
                      <td className="border border-gray-300 p-1 text-right text-xs font-semibold w-[20%]">
                        ₹{product.price.toFixed(2)}
                      </td>
                      <td className="border border-gray-300 p-1 text-right text-xs font-semibold w-[20%]">
                        <div className="flex flex-col items-end text-xs">
                          <span>Total: ₹{product.total_amount.toFixed(2)}</span>
                          <span>SGST: ₹{product.sgst.toFixed(2)}</span>
                          <span>IGST: ₹{product.igst.toFixed(2)}</span>
                          <span>CGST: ₹{product.cgst.toFixed(2)}</span>
                          <span>
                            Grand Total: ₹{product.grandtotal.toFixed(2)}
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="5"
                      className="border border-gray-300 p-2 text-center text-gray-500"
                    >
                      No products available.
                    </td>
                  </tr>
                )}
              </tbody>
              <tfoot>
                <tr className="bg-gray-300">
                  <td
                    colSpan="4"
                    className="text-right font-semibold p-2 border-t border-gray-400 text-xs"
                  >
                    Grand Total:
                  </td>
                  <td className="text-right font-semibold p-2 border-t border-gray-400 text-sm">
                    ₹{grandTotal.toFixed(2)}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>

      {/* Print and Download Button */}
      <div className="mt-6 text-center">
        <button
          onClick={handlePrint} // Call handlePrint when clicked
          className="bg-[#dcbbfe] text-white px-4 py-2 rounded-md shadow-md"
        >
          Print Receipt
        </button>
      </div>

      <button
        onClick={() => navigate("/admin")} // Navigate to the previous page
        className="mb-6 mt-4 px-4 py-2 bg-indigo-600 text-white rounded-md shadow-md hover:bg-indigo-700"
      >
        Back to Previous page
      </button>
    </div>
  );
};

export default Receipt;
