import DashboardSection from './Dashbord';
import OrderSection from './OrderSection';
import UserProfile from './UserProfile';
// import UserSetting from './UserSetting';
import React, { useState, useEffect } from 'react';

const UserSidebar = () => {
  const [activeSection, setActiveSection] = useState('dashboard');
  const [isOpen, setIsOpen] = useState(true); // Open by default on mobile

  useEffect(() => {
    // Adding animation for when the page loads
    const sidebar = document.getElementById('sidebar');
    if (sidebar) {
      sidebar.classList.add('slide-in'); // Add class for animation
    }

    // Cleanup on unmount to remove animation
    return () => {
      if (sidebar) {
        sidebar.classList.remove('slide-in');
      }
    };
  }, []); // Empty dependency array to run once on component mount

  const renderSection = () => {
    switch (activeSection) {
      case 'dashboard':
        return <DashboardSection />;
      case 'orders':
        return <OrderSection />;
      case 'profile':
        return <UserProfile />;
      default:
        return <DashboardSection />;
    }
  };

  return (
    <div className="bg-gray-50">
      <div className="md:flex bg-gray-50 justify-between max-w-8xl mx-auto pb-5">
        {/* Mobile Menu Button */}
        <div className="md:hidden flex gap-x-3 bg-[#C77A2B] px-4 py-2 text-white w-full">
          <button onClick={() => setIsOpen(!isOpen)} className="focus:outline-none text-white">
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              {isOpen ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              )}
            </svg>
          </button>
          <h1 className="text-xl font-bold">User Dashboard</h1>
        </div>

        {/* Sidebar */}
        <div
          id="sidebar"
          className={`inset-y-0 left-0 md:w-56 w-full bg-[#C77A2B] shadow-lg transform ${
            isOpen ? 'translate-x-0' : '-translate-x-full'
          } md:translate-x-0 transition-transform duration-500 ease-in-out`}
        >
          <nav className="mt-1">
            <button
              onClick={() => setActiveSection('dashboard')}
              className={`block w-full text-left py-2.5 px-4 rounded transition duration-200 ${
                activeSection === 'dashboard'
                  ? 'bg-[#F5DDC6] text-black'
                  : 'text-white hover:bg-[#F5DDC6] hover:text-black'
              }`}
            >
              Dashboard
            </button>

            <button
              onClick={() => setActiveSection('orders')}
              className={`block w-full  text-left py-2.5 px-4 rounded transition duration-200 ${
                activeSection === 'orders' ? 'bg-[#F5DDC6] text-black' : 'text-white hover:bg-[#F5DDC6] hover:text-black'
              }`}
            >
              Orders
            </button>
            <button
              onClick={() => setActiveSection('profile')}
              className={`block w-full text-left py-2.5 px-4 rounded transition duration-200 ${
                activeSection === 'profile' ? 'bg-[#F5DDC6] text-black' : 'text-white hover:bg-[#F5DDC6] hover:text-black'
              }`}
            >
              Profile
            </button>
          </nav>
        </div>

        {/* Main Content */}
        <div className="flex-1">{renderSection()}</div>
      </div>
    </div>
  );
};

export default UserSidebar;
