import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import ApiURl from "../../controllers/Api";

function Dashboard() {
  const [totalSales, setTotalSales] = useState(0);
  const [newOrders, setNewOrders] = useState(0);
  const [pendingOrders, setPendingOrders] = useState(0);
  const [totalorder, setTotalorder] = useState(0);
  const [recentOrders, setRecentOrders] = useState([]);
  const [latestProducts, setLatestProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentOrderIndex, setCurrentOrderIndex] = useState(0);
  const [isDataUpdated, setIsDataUpdated] = useState(false); // Track data updates
  const [filterVisible, setFilterVisible] = useState(false); // Toggle for filter panel
  const [filteredOrders, setFilteredOrders] = useState(recentOrders);
  const [statusFilter, setStatusFilter] = useState(""); // Store selected status filter
  const [dateFrom, setDateFrom] = useState(""); // Store start date for filtering
  const [dateTo, setDateTo] = useState(""); // Store end date for filtering
  const filterPanelRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const role = localStorage.getItem("role");
    if (role !== "admin") {
      navigate("/unauthorized");
    }

    const fetchDashboardData = async () => {
      try {
        const response = await fetch(`${ApiURl}/getDashboardData.php`);
        const data = await response.json();

        if (data?.success) {
          const dashboardData = data?.data || {};

          let updated = false;
          // Check if data has changed, and update state only if there's a change
          if (dashboardData.total_sales !== totalSales) {
            setTotalSales(dashboardData.total_sales || 0);
            updated = true;
          }

          if (dashboardData.new_orders !== newOrders) {
            setNewOrders(dashboardData.new_orders || 0);
            updated = true;
          }

          if (dashboardData.total_orders !== totalorder) {
            setTotalorder(dashboardData.total_orders || 0);
            updated = true;
          }

          if (dashboardData.pending_orders !== pendingOrders) {
            setPendingOrders(dashboardData.pending_orders || 0);
            updated = true;
          }

          if (
            JSON.stringify(dashboardData.recent_orders) !==
            JSON.stringify(recentOrders)
          ) {
            setRecentOrders(dashboardData.recent_orders || []);
            updated = true;
          }

          if (
            JSON.stringify(dashboardData.latest_products) !==
            JSON.stringify(latestProducts)
          ) {
            setLatestProducts(dashboardData.latest_products || []);
            updated = true;
          }

          if (updated) {
            setIsDataUpdated(true); // Mark data as updated
          }
          setLoading(false); // Stop the loading state once data is fetched
        } else {
          setError(data?.message || "Failed to fetch dashboard data.");
          setLoading(false); // Stop loading even if there's an error
        }
      } catch (error) {
        setError("An error occurred while fetching data. Please try again.");
        setLoading(false); // Stop loading on error
      }
    };

    fetchDashboardData();

    // Set interval for periodic refresh every 10 seconds (or adjust as needed)
    const intervalId = setInterval(() => {
      fetchDashboardData();
    }, 1000); // Adjust interval to your needs (10 seconds in this case)

    // Cleanup interval when component unmounts
    return () => clearInterval(intervalId);
  }, [
    totalSales,
    newOrders,
    pendingOrders,
    totalorder,
    recentOrders,
    latestProducts,
    navigate,
  ]);
  // Handle Filter Open and Close
  const toggleFilterPanel = () => setFilterVisible(!filterVisible);

  useEffect(() => {
    // Close the filter panel if clicked outside
    const handleClickOutside = (event) => {
      if (
        filterPanelRef.current &&
        !filterPanelRef.current.contains(event.target)
      ) {
        setFilterVisible(false); // Close the filter panel
      }
    };

    // Attach event listener to handle outside click
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Handle Filtering Logic
  const applyFilters = () => {
    const filtered = recentOrders.filter((order) => {
      let matches = true;

      console.log(order);

      // Filter by status
      if (statusFilter && order.status !== statusFilter) {
        matches = false;
      }

      // Filter by date range
      if (dateFrom && new Date(order.order_date) < new Date(dateFrom)) {
        matches = false;
      }
      if (dateTo && new Date(order.order_date) > new Date(dateTo)) {
        matches = false;
      }

      return matches;
    });

    setFilteredOrders(filtered); // Update the filtered orders
    toggleFilterPanel(); // Close the filter panel after applying
  };

  // Reset filters
  const resetFilters = () => {
    setStatusFilter("");
    setDateFrom("");
    setDateTo("");
    setFilteredOrders(recentOrders); // Reset to the original data
  };

  const updateOrderStatus = async (orderId) => {
    const isConfirmed = window.confirm(
      `Are you sure you want to confirm order #${orderId}?`
    );

    if (!isConfirmed) return; // If user cancels, don't proceed
    try {
      const response = await fetch(`${ApiURl}/updateOrderStatus.php`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ order_id: orderId, status: "completed" }),
      });
      const result = await response.json();

      if (result?.success) {
        setRecentOrders((prevOrders) =>
          prevOrders.map((order) =>
            order.order_id === orderId
              ? { ...order, status: "completed" }
              : order
          )
        );
        alert("Order marked as completed!");
      } else {
        alert(result?.message || "Failed to update order status.");
      }
    } catch (error) {
      alert("An error occurred while updating order status.");
    }
  };
  const cancelOrderStatus = async (orderId) => {
    const isConfirmed = window.confirm(
      `Are you sure you want to cancel order #${orderId}?`
    );

    if (!isConfirmed) return; // If user cancels, don't proceed
    try {
      const response = await fetch(`${ApiURl}/updateOrderStatus.php`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ order_id: orderId, status: "cancelled" }), // Set the status to "cancelled"
      });

      const result = await response.json();

      // Log the response to see what it contains
      console.log(result);

      if (result?.success) {
        setRecentOrders((prevOrders) =>
          prevOrders.map((order) =>
            order.order_id === orderId
              ? { ...order, status: "cancelled" } // Update the status in the UI
              : order
          )
        );
        alert("Order marked as cancelled!");
      } else {
        alert(result?.message || "Failed to cancel order.");
      }
    } catch (error) {
      alert("An error occurred while cancelling the order.");
    }
  };

  const currentOrder =
    recentOrders.length > 0 ? recentOrders[currentOrderIndex] : null;

  return (
    <div className="p-6 bg-gray-100">
      {/* Summary Section with Notifications in the top-right corner */}
      <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-5 gap-6 mb-8 relative">
        {[
          { label: "Total Sales", value: totalSales, color: "bg-[#E8CBA7]" },
          { label: "Total Orders", value: totalorder, color: "bg-[#E8CBA7]" },
          {
            label: "Completed Orders",
            value: newOrders,
            color: "bg-[#E8CBA7]",
          },
          {
            label: "Pending Orders",
            value: pendingOrders,
            color: "bg-[#E8CBA7]",
          },
        ].map((item, idx) => (
          <div
            key={idx}
            className={`text-white p-6 rounded-lg shadow-lg hover:scale-105 hover:bg-[#C77A2B] transition-all duration-300 ${item.color}`}
          >
            <h2 className="text-lg font-semibold text-black ">{item.label}</h2>
            {loading ? (
              <p className="mt-4 text-2xl animate-pulse ">Loading...</p>
            ) : error ? (
              <p className="mt-4 text-2xl text-red-500">{error}</p>
            ) : (
              <p
                id={`${item.label}`}
                className={`mt-4 text-2xl  font-bold text-black  fade-in ${
                  (item.value !== totalSales, item.value !== totalorder)
                }`}
              >
                {item.value}
              </p>
            )}
          </div>
        ))}
        <div className="hidden lg:block">
          <div className="absolute top-0 right-4 flex items-center justify-center w-56 h-32 transition-transform transform hover:scale-105">
            <div className="relative w-full h-full p-4 bg-white rounded-lg shadow-lg flex flex-col items-center justify-center space-y-3 transition-all duration-300 ease-in-out">
              <h3 className="text-lg font-semibold text-gray-800 text-center relative z-10">
                Notifications
              </h3>

              {loading ? (
                <p className="text-center text-sm text-gray-500 animate-pulse">
                  Loading...
                </p>
              ) : currentOrder ? (
                <div
                  className={`w-48 h-40 flex items-center justify-center rounded-full mx-auto shadow-lg transition-all duration-300 ease-in-out ${
                    currentOrder.status === "completed"
                      ? "bg-green-500 transform scale-105"
                      : currentOrder.status === "pending"
                      ? "bg-yellow-300  transform scale-105"
                      : "bg-gray-500 transform scale-105"
                  }`}
                >
                  <div className="text-center text-black ">
                    <p className="font-bold text-sm">
                      Order ID: #{currentOrder.order_id}
                    </p>
                    <p className="font-bold text-sm">
                      ₹{currentOrder.total_amount}
                    </p>
                    <p className="font-semibold text-sm">
                      Status:{" "}
                      <span
                        className={`px-3 py-1 text-sm rounded-full font-semibold ${
                          currentOrder.status === "completed"
                            ? "bg-green-100 text-green-800"
                            : currentOrder.status === "pending"
                            ? "bg-yellow-100 text-yellow-800"
                            : currentOrder.status === "cancelled"
                            ? "bg-red-100 text-red-800" // Add this line for cancelled
                            : "bg-gray-100 text-gray-800"
                        }`}
                      >
                        {currentOrder.status}
                      </span>
                    </p>
                  </div>
                </div>
              ) : (
                <p className="text-center text-sm text-gray-500 animate-fadeIn">
                  No orders available.
                </p>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Recent Orders and Completed Orders Section */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {/* Recent Orders */}
        <div className="col-span-1 sm:col-span-2 lg:col-span-2 bg-white p-6 rounded-lg shadow-lg transition-all duration-300">
          {/* Filter Dropdown */}
          <div className="relative inline-block text-left float-right">
            <button
              onClick={toggleFilterPanel}
              className="bg-indigo-600 text-white px-4 py-2 rounded-md"
            >
              Filter Orders
            </button>

            {/* Dropdown Menu */}
            {filterVisible && (
              <div
                ref={filterPanelRef}
                className="absolute right-0 mt-2 w-56 bg-white border border-gray-300 rounded-md shadow-lg"
              >
                <div className="p-4">
                  <h3 className="text-xl font-semibold mb-4">Filter Orders</h3>

                  {/* Status Filter */}
                  <div className="mb-4">
                    <label className="block text-sm font-semibold">
                      Order Status
                    </label>
                    <select
                      value={statusFilter}
                      onChange={(e) => setStatusFilter(e.target.value)}
                      className="w-full p-2 border border-gray-300 rounded-md"
                    >
                      <option value="">All</option>
                      <option value="completed">Completed</option>
                      <option value="pending">Pending</option>
                      <option value="cancelled">Cancelled</option>
                    </select>
                  </div>

                  {/* Date Range Filters */}
                  <div className="mb-4">
                    <label className="block text-sm font-semibold">
                      Date From
                    </label>
                    <input
                      type="date"
                      value={dateFrom}
                      onChange={(e) => setDateFrom(e.target.value)}
                      className="w-full p-2 border border-gray-300 rounded-md"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-sm font-semibold">
                      Date To
                    </label>
                    <input
                      type="date"
                      value={dateTo}
                      onChange={(e) => setDateTo(e.target.value)}
                      className="w-full p-2 border border-gray-300 rounded-md"
                    />
                  </div>

                  {/* Apply and Reset Buttons */}
                  <div className="flex justify-between">
                    <button
                      onClick={resetFilters}
                      className="bg-gray-400 text-white px-4 py-2 rounded-md"
                    >
                      Reset
                    </button>
                    <button
                      onClick={applyFilters}
                      className="bg-indigo-600 text-white px-4 py-2 rounded-md"
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
          <h3 className="text-xl font-bold mb-4">Recent Orders</h3>
          <div className="overflow-x-auto">
            {loading ? (
              <p className="text-gray-700 animate-pulse">Loading...</p>
            ) : error ? (
              <p className="text-red-500">{error}</p>
            ) : recentOrders.length > 0 ? (
              <>
                {/* Desktop View: Table layout */}
                <div className="hidden lg:block">
                  <div className="overflow-x-auto max-h-[300px] md:max-h-[450px]">
                    <table className=" min-w-full table-auto">
                      <thead className="sticky top-0 bg-white z-10">
                        <tr>
                          <th className="px-4 py-2 text-left font-semibold text-xs sm:text-base">
                            Order No
                          </th>
                          <th className="px-4 py-2 text-left font-semibold text-xs sm:text-base">
                            Customer Name
                          </th>
                          <th className="px-4 py-2 text-left font-semibold text-xs sm:text-base">
                            Date & Time
                          </th>
                          <th className="px-4 py-2 text-left font-semibold text-xs sm:text-base">
                            Price
                          </th>
                          <th className="px-4 py-2 text-left font-semibold text-xs sm:text-base">
                            Status
                          </th>
                          <th className="px-4 py-2 text-left font-semibold text-xs sm:text-base">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* Render all orders if no filters are applied */}
                        {(statusFilter || dateFrom || dateTo
                          ? filteredOrders
                          : recentOrders
                        ).map((order) => (
                          <tr key={order.order_id} className="hover:bg-gray-50">
                            <td className="px-4 py-2 text-xs sm:text-base">
                              {order.order_id}
                            </td>
                            <td className="px-4 py-2 text-xs sm:text-base">
                              {order.full_name}
                            </td>
                            <td className="px-4 py-2 text-xs sm:text-base">
                              {order.order_date}
                            </td>
                            <td className="px-4 py-2 text-xs sm:text-base">
                              ₹{order.total_amount}
                            </td>
                            <td className="px-4 py-2">
                              <span
                                className={`px-3 py-1 text-xs rounded-full font-semibold ${
                                  order.status === "completed"
                                    ? "bg-green-100 text-green-800"
                                    : order.status === "pending"
                                    ? "bg-yellow-100 text-yellow-800"
                                    : order.status === "cancelled"
                                    ? "bg-red-100 text-red-800"
                                    : "bg-gray-100 text-gray-800"
                                }`}
                              >
                                {order.status}
                              </span>
                            </td>
                            <td className="px-4 py-2 text-xs sm:text-base">
                              <div className="flex flex-col sm:flex-row sm:space-x-2">
                                {/* View Details Button */}
                                <button
                                  onClick={() =>
                                    navigate(
                                      `/AdminViewDetails/${order.order_id}`
                                    )
                                  }
                                  className="bg-blue-500 text-white text-xs px-2 py-1 rounded-md hover:bg-blue-600 mb-1 sm:mb-0"
                                >
                                  Details
                                </button>

                                {/* Eye Button for Receipt (Desktop) */}
                                {order.status !== "cancelled" && (
                                  <button
                                    onClick={() =>
                                      navigate(
                                        `/AdminReceipt/${order.order_id}`
                                      )
                                    }
                                    className="text-xs px-4 py-1 rounded-md font-semibold transition-all bg-indigo-500 text-gray-100 hover:bg-gray-400 mb-1 sm:mb-0"
                                  >
                                    Bill
                                    {/* Eye Icon on Receipt Button */}
                                  </button>
                                )}

                                {/* Complete Button */}
                                <button
                                  onClick={() =>
                                    updateOrderStatus(order.order_id)
                                  }
                                  className={`text-xs sm:text-sm px-2 py-1 rounded-md font-semibold transition-all ${
                                    order.status === "completed"
                                      ? "bg-gray-400 text-gray-800 cursor-not-allowed"
                                      : "bg-blue-500 text-white hover:bg-blue-600"
                                  }`}
                                  disabled={
                                    order.status === "completed" ||
                                    order.status === "cancelled"
                                  }
                                >
                                  Complete
                                </button>

                                {/* Cancel Button */}
                                <button
                                  onClick={() =>
                                    cancelOrderStatus(order.order_id)
                                  }
                                  className={`text-xs sm:text-sm px-2 py-1 rounded-md font-semibold transition-all ${
                                    order.status === "cancelled"
                                      ? "bg-gray-400 text-gray-800 cursor-not-allowed"
                                      : "bg-red-500 text-white hover:bg-red-600"
                                  }`}
                                  disabled={
                                    order.status === "completed" ||
                                    order.status === "cancelled"
                                  }
                                >
                                  Cancel
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>

                {/* Mobile View: Table layout with full-width rows */}
                <div className="lg:hidden">
                  <ul className="space-y-4">
                    {/* Render all orders if no filters are applied */}
                    {(statusFilter || dateFrom || dateTo
                      ? filteredOrders
                      : recentOrders
                    ).map((order) => (
                      <li
                        key={order.order_id}
                        className="flex flex-col bg-white p-4 border rounded-lg shadow-sm overflow-hidden hover:shadow-lg transition-all ease-in-out duration-200 animate__animated animate__fadeIn"
                      >
                        {/* Data Columns */}
                        <div className="mb-3">
                          <div className="flex justify-between items-center">
                            <span className="font-semibold text-sm sm:text-base text-left w-1/2">
                              Order No:
                            </span>
                            <span className="text-sm sm:text-base text-left">
                              {order.order_id}
                            </span>
                          </div>
                        </div>
                        <div className="mb-3">
                          <div className="flex justify-between items-center">
                            <span className="font-semibold text-sm sm:text-base text-left w-1/2">
                              Username:
                            </span>
                            <span className="text-sm sm:text-base text-left">
                              {order.full_name}
                            </span>
                          </div>
                        </div>
                        <div className="mb-3">
                          <div className="flex justify-between items-center">
                            <span className="font-semibold text-sm sm:text-base text-left w-1/2">
                              Date & Time:
                            </span>
                            <span className="text-sm sm:text-base text-left">
                              {order.order_date}
                            </span>
                          </div>
                        </div>
                        <div className="mb-3">
                          <div className="flex justify-between items-center">
                            <span className="font-semibold text-sm sm:text-base text-left w-1/2">
                              Price:
                            </span>
                            <span className="text-sm sm:text-base text-left">
                              ₹{order.total_amount}
                            </span>
                          </div>
                        </div>
                        <div className="mb-3">
                          <div className="flex justify-between items-center">
                            <span className="font-semibold text-sm sm:text-base text-left w-1/2">
                              Status:
                            </span>
                            <span
                              className={`px-3 py-1 text-xs rounded-full font-semibold ${
                                order.status === "completed"
                                  ? "bg-green-100 text-green-800"
                                  : order.status === "pending"
                                  ? "bg-yellow-100 text-yellow-800"
                                  : order.status === "cancelled"
                                  ? "bg-red-100 text-red-800"
                                  : "bg-gray-100 text-gray-800"
                              } text-left`}
                            >
                              {order.status}
                            </span>
                          </div>
                        </div>

                        {/* Buttons Section */}
                        <div className="mt-4 flex flex-col sm:flex-row sm:space-x-4 items-center justify-between">
                          {/* View Details Button */}
                          <button
                            onClick={() =>
                              navigate(`/AdminViewDetails/${order.order_id}`)
                            }
                            className="bg-blue-500 text-white text-sm px-3 py-1 rounded-md hover:bg-blue-600 mb-2 sm:mb-0 w-full sm:w-auto"
                          >
                            View Details
                          </button>

                          {/* Bill Button */}
                          {order.status !== "cancelled" && (
                            <button
                              onClick={() =>
                                navigate(`/AdminReceipt/${order.order_id}`)
                              }
                              className="text-xs px-4 py-1 rounded-md font-semibold transition-all bg-indigo-500 text-gray-100 hover:bg-gray-400 mb-2 sm:mb-0 w-full sm:w-auto"
                            >
                              Bill
                            </button>
                          )}

                          {/* Complete Button */}
                          <button
                            onClick={() => updateOrderStatus(order.order_id)}
                            className={`text-xs sm:text-sm px-3 py-1 rounded-md font-semibold transition-all ${
                              order.status === "completed"
                                ? "bg-gray-400 text-gray-800 cursor-not-allowed"
                                : "bg-blue-500 text-white hover:bg-blue-600"
                            } w-full sm:w-auto mb-2 sm:mb-0`}
                            disabled={order.status === "completed"}
                          >
                            Complete
                          </button>

                          {/* Cancel Button */}
                          <button
                            onClick={() => cancelOrderStatus(order.order_id)}
                            className={`text-xs sm:text-sm px-4 py-2 rounded-md font-semibold transition-all ${
                              order.status === "cancelled"
                                ? "bg-gray-400 text-gray-800 cursor-not-allowed"
                                : "bg-red-500 text-white hover:bg-red-600"
                            } w-full sm:w-auto`}
                            disabled={
                              order.status === "completed" ||
                              order.status === "cancelled"
                            }
                          >
                            Cancel
                          </button>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </>
            ) : (
              <p>No recent orders found.</p>
            )}
          </div>
        </div>

        {/* Completed Orders */}
        <div className="col-span-1 sm:col-span-2 lg:col-span-1 bg-white p-6 rounded-lg shadow-lg transition-all duration-300">
          <h3
            className="text-xl 
          font-bold mb-4"
          >
            Completed Orders
          </h3>
          <div
            className="space-y-4 overflow-y-auto"
            style={{ maxHeight: "450px" }}
          >
            {loading ? (
              <p className="text-gray-700 animate-pulse">Loading...</p>
            ) : error ? (
              <p className="text-red-500">{error}</p>
            ) : recentOrders.filter((order) => order.status === "completed")
                .length > 0 ? (
              <ul>
                {recentOrders
                  .filter((order) => order.status === "completed")
                  .map((order) => (
                    <li
                      key={order.order_id}
                      className="flex justify-between items-center hover:bg-gray-50 p-3 rounded transition-all"
                    >
                      <span className="font-semibold">
                        Order #{order.order_id}
                      </span>

                      {/* <span className="font-semibold">Date & Time:</span> */}
                      <span>{order.order_date}</span>

                      <span className="font-medium text-gray-900 px-6">
                        ₹{order.total_amount}
                      </span>
                      <div className="flex items-center justify-end space-x-2">
                        <button
                          onClick={() =>
                            navigate(`/AdminViewDetails/${order.order_id}`)
                          }
                          className="bg-blue-500 text-white text-xs px-4 py-2 rounded-md hover:bg-blue-600  whitespace-nowrap"
                        >
                          View Details
                        </button>
                      </div>
                    </li>
                  ))}
              </ul>
            ) : (
              <p>No completed orders found.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
