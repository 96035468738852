import React, { useEffect, useState } from "react";
import ApiURl from "../controllers/Api";
import $ from "jquery";

function CheckoutProductsView() {
  const [cartItems, setCartItems] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state for API call
  const [error, setError] = useState(null); // Error state for API call

  useEffect(() => {
    const fetchCartItems = async () => {
      setLoading(true); // Set loading state to true before making API call
      try {
        // Fetch the cart_id from localStorage
        const cartId = localStorage.getItem("cart_id");

        if (!cartId) {
          setError("Cart ID not found in localStorage.");
          setLoading(false);
          return;
        }

        // Fetch the cart data from the PHP API using the cartId from localStorage
        const response = await $.getJSON(
          `${ApiURl}/getCartItems.php?cart_id=${cartId}`
        );

        if (response.status === "success") {
          // Only update the state with selected items
          const selectedItems = response.data.filter(item => item.selected === 1);
          setCartItems(selectedItems);
        } else {
          setError("No selected items found in the cart.");
        }
      } catch (error) {
        setError("Failed to fetch cart items. Please try again later.");
      } finally {
        setLoading(false); // Set loading state to false after the API call is done
      }
    };

    fetchCartItems();
  }, []);

  return (
    <div className="mt-6 min-w-[25vw] w-full space-y-6 sm:mt-8 lg:mt-16 lg:max-w-xs xl:max-w-md">
      <div className="space-y-6 rounded-lg border border-gray-200 bg-white p-4 shadow-sm sm:p-6">
        <dt className="text-base text-center border-b pb-2 font-normal text-gray-500">
          Items
        </dt>

        {/* Loading State */}
        {loading && (
          <p className="text-sm text-gray-500 text-center">Loading...</p>
        )}

        {/* Error State */}
        {error && (
          <p className="text-sm text-red-500 text-center">{error}</p>
        )}

        <div className="-my-3 divide-y divide-gray-200">
          {cartItems.length > 0 ? (
            cartItems.map((item, index) => (
              <dl key={index} className="flex items-center justify-between gap-4 pb-3">
                <dt className="text-sm font-medium w-10 whitespace-nowrap text-gray-500">
                  {item.name}
                </dt>
                <dt className="text-sm font-normal text-gray-500">
                  Qty: <span className="font-medium">{item.quantity}</span>
                </dt>

                <dd className="text-sm font-medium text-gray-900">
                  &#x20b9;{item.total}
                </dd>
              </dl>
            ))
          ) : (
            <p className="text-sm text-gray-500 text-center">No selected items in cart.</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default CheckoutProductsView;
