import React from "react";
import About from "../images/Aboutus.png";
const AboutUs = () => {
  return (
    <section className="bg-gradient-to-r from-[#C77A2B] via-[#E5AA70] to-[#C77A2B] overflow-hidden py-16">
      <div className="max-w-7xl mx-auto px-6 md:px-10">
        <div className="flex flex-col-reverse md:flex-row items-center gap-10">
          {/* Text Section */}
          <div
            className="text-center md:text-left flex-1"
            style={{
              animation: "fadeInLeft 1.5s ease-in-out forwards",
              opacity: 0,
            }}
          >
            <h2 className="text-4xl font-bold text-gray-800 mb-6">About Us</h2>
            <p className="text-lg text-gray-700 leading-relaxed">
              Welcome to{" "}
              <span className="font-semibold text-gray-800">
                Bhatia Emporium
              </span>
              , your one-stop solution for quality home and kitchen products.
              With over 10+ years of experience, we pride ourselves on
              delivering exceptional products that meet the highest standards of
              quality.
            </p>
            <p className="text-lg text-gray-700 leading-relaxed mt-4">
              Since 1969, we have been pioneers in PVC products, building a
              reputation for providing durable and reliable solutions. With over
              15 years of expertise in Export and Import, we’ve expanded our
              reach internationally, ensuring top-quality products from around
              the world.
            </p>

            <p className="text-lg text-gray-700 leading-relaxed mt-4">
              Our mission is to bring innovation and excellence to your
              doorstep, ensuring your satisfaction and trust in every purchase.
              Whether you're looking for home essentials or high-quality
              kitchenware, we are committed to delivering top-notch products
              that make your life easier and more comfortable.
            </p>
          </div>

          {/* Image Section */}
          <div
            className="flex-1"
            style={{
              animation: "fadeInRight 1.5s ease-in-out forwards",
              opacity: 0,
            }}
          >
            <img
              src={About} // Replace with your image path
              alt="About Us"
              className="rounded-lg md:ml-auto"
              style={{
                boxShadow: "10px 0px 15px -5px rgba(0, 0, 0, 0.2)", // Adds a shadow effect that appears mainly on the left
              }}
            />
          </div>
        </div>
      </div>

      {/* Add Keyframes CSS */}
      <style>{`
        @keyframes fadeInLeft {
          from {
            opacity: 0;
            transform: translateX(-50px);
          }
          to {
            opacity: 1;
            transform: translateX(0);
          }
        }
        @keyframes fadeInRight {
          from {
            opacity: 0;
            transform: translateX(50px);
          }
          to {
            opacity: 1;
            transform: translateX(0);
          }
        }
      `}</style>
    </section>
  );
};

export default AboutUs;