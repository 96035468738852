import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CheckoutProductsView from "./checkoutProductsview";
import ApiURl from "../controllers/Api";
import $ from "jquery";
import CheckoutReceipt from "./CheckoutReceipt";

function Checkout() {
  const [address, setAddress] = useState({});
  const [cart, setCart] = useState([]);
  const [cartId, setCartid] = useState(localStorage.getItem("cart_id") || "");
  const [addresses, setAddresses] = useState([]); // For storing multiple addresses
  const [savedAddresses, setSavedAddresses] = useState([]); // For storing saved addresses
  const [isAddingNewAddress, setIsAddingNewAddress] = useState(false); // To toggle new address form
  const [showAllAddresses, setShowAllAddresses] = useState(false); // State for toggling between default and all addresses
  const totalamount = localStorage.getItem("totalAmount");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${ApiURl}/recipetdetail.php?cart_id=${cartId}`
        );
        const data = await response.json();
        if (data.status === "success") {
          const filteredItems = data.data.filter((item) => item.selected === 1);
          setCart(filteredItems);
        } else {
          console.error("Error fetching cart data:", data.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [cartId]);

  useEffect(() => {
    const fetchData = async () => {
      const userId = localStorage.getItem("user_id");

      if (!userId) {
        console.error("Error: User ID not found in local storage.");
        alert("Please log in to continue.");
        return;
      }

      try {
        const response = await $.ajax({
          url: `${ApiURl}/getCartItems.php`,
          type: "POST",
          contentType: "application/json",
          data: JSON.stringify({ user_id: userId, cart_id: cartId }),
          dataType: "json",
        });

        if (response.success) {
          setCart(response.data);
          console.log(response.data);
        } else {
          console.error("Error fetching data:", response.message);
          alert(response.message || "Failed to fetch cart items.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [cartId]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const orderData = {
      user_id: localStorage.getItem("user_id"),
      address: { ...address},
      total: totalamount,
      items: cart.map((item) => ({
        product_id: item.product_id,
        quantity: item.quantity,
        price: item.price,
        total: item.total,
        size: item.size,
        hsn_code: item.hsn_code,
        selected: item.selected,
      })),
    };

    address["user_id"] = localStorage.getItem("user_id");

    console.log([totalamount]);

    try {
      const response = await $.ajax({
        url: `${ApiURl}/createOrder.php`,
        type: "POST",
        dataType: "json",
        data: JSON.stringify(orderData),
      });

      console.log(response);
      if (response.order_id) {
        // Show modal instead of alert
        setIsModalVisible(true); // Show confirmation modal
       console.log(orderData);
       console.log(response);
      

        try {
          const deleteResponse = await $.ajax({
            url: `${ApiURl}/clearCart.php`,
            type: "POST",
            contentType: "application/json",
            data: JSON.stringify({ cart_id: cartId }),
          });

          if (deleteResponse.success) {
           
            localStorage.removeItem("cart");
            localStorage.removeItem("totalAmount");
            setCart([]);
           
          } else {
            console.error("Failed to clear cart:", deleteResponse.message);
            alert("Failed to clear cart. Please try again.");
          }
        } catch (error) {
          console.error("Error clearing cart:", error);
          alert("An error occurred while clearing the cart.");
        }
      } else {
        alert("Failed to confirm order. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting order:", error.responseText);
      alert("An error occurred while processing your order.");
    }
  };
  const ConfirmationModal = ({ isVisible, onClose }) => {
    return (
      isVisible && (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
          <div
            className="bg-gradient-to-r from-indigo-600 to-purple-600 p-8 rounded-lg shadow-lg transform transition-all duration-700 ease-in-out scale-110"
            style={{
              animation: 'fadeIn 0.5s ease-in-out, slideUp 1s ease-out',
            }}
          >
            <div className="flex justify-center mb-4">
              <span className="text-5xl text-white animate-pulse">🎉</span>
            </div>
  
            <h2 className="text-3xl font-bold text-white text-center animate__animated animate__fadeIn animate__delay-1s">
              Congratulations!
            </h2>
            <p className="mt-2 text-xl text-white text-center animate__animated animate__fadeIn animate__delay-2s">
              Your order has been confirmed.
            </p>
  
            <div className="mt-6 text-center">
              <button
                onClick={onClose} // Close the modal and navigate to home
                className="bg-yellow-400 text-white py-3 px-6 rounded-lg text-xl font-semibold hover:bg-yellow-500 transition-all duration-300 ease-in-out transform hover:scale-105"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      )
    );
  };
  
  const handleModalClose = () => {
    setIsModalVisible(false);  // Hide modal
    navigate("/");  // Redirect to home page
  
    // Scroll to the top after the page is loaded
    window.scrollTo(0, 0);  // Scroll to top of the page
  };

  const handleData = (e) => {
    setAddress({
      ...address,
      [e.target.name]: e.target.value,
      total: totalamount // Directly set the total amount from localStorage
    });
  };

  const handleAddressSelect = (selectedAddress) => {
    setAddress(selectedAddress); // Fill the input fields with selected address
  };

  const handleAddNewAddress = () => {
    setIsAddingNewAddress(true); // Enable adding new address
  };

  // Fetch addresses when component mounts
  useEffect(() => {
    const fetchAddresses = async () => {
      const userId = localStorage.getItem("user_id");
      if (!userId) return;

      try {
        const response = await $.ajax({
          url: `${ApiURl}/getUserAddress.php?user_id=${userId}`, // Send user_id as a query parameter
          type: "GET", // Change to GET
          dataType: "json",
        });
        console.log(response);

        if (response.success) {
          setAddresses(response.data);
          console.log(response.data);
          setSavedAddresses(response.data.addresses);
          // Select the first address by default if exists
          if (response.data.addresses.length > 0) {
            setAddress(response.data.addresses[0]);
          }
        } else {
          console.error("Error fetching addresses:", response.message);
        }
      } catch (error) {
        console.error("Error fetching addresses:", error);
      }
    };

    fetchAddresses();
  }, []);

  const toggleAddressView = () => {
    setShowAllAddresses((prevState) => !prevState);
  };

  // Filtered addresses based on the toggle state
  const addressesToDisplay = showAllAddresses
    ? savedAddresses
    : savedAddresses.filter((addr) => addr.is_default);

  // To reset the address form when deselecting a saved address
  const handleAddressDeselect = (addressToDeselect) => {
    if (address.address_id === addressToDeselect.address_id) {
      setAddress({}); // Deselect the address by resetting the address state
    } else {
      setAddress(addressToDeselect); // Select the clicked address
    }
  };

  const total_amount = totalamount;

  console.log(totalamount);

  return (
    <section className="bg-gray-50 py-4 antialiased md:py-6 border transition-all duration-300 ease-in-out">
      <h1 className="text-center font-semibold text-lg border-b border-gray-300 pb-2 mb-4 text-gray-800">
        Checkout
      </h1>
      <form
        action="#"
        onSubmit={handleSubmit}
        className="mx-auto max-w-7xl px-4 2xl:px-0 space-y-6"
      >
        <div className="lg:grid lg:grid-cols-2 lg:gap-4 xl:gap-6 space-y-6 lg:space-y-0">
          {/* Left Column: Address */}
          <div className="space-y-6">
            {/* Delivery Address Section */}
            <div className="bg-white p-4 rounded-lg shadow-sm space-y-4 border border-gray-200">
              <h1 className="text-base text-xl font-semibold text-gray-800">
                Delivery Address
              </h1>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                {/* Address Inputs */}
                <div>
                  <label className="block text-sm font-semibold text-gray-700">
                    Full Name
                  </label>
                  <input
                    type="text"
                    name="full_name"
                    value={address.full_name || ""}
                    onChange={handleData}
                    className="w-full border border-gray-300 p-2 rounded-md focus:ring-2 focus:ring-indigo-400 transition-all duration-300 ease-in-out"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-semibold text-gray-700">
                    Mobile No
                  </label>
                  <input
                    type="text"
                    name="phone"
                    value={address.phone || ""}
                    onChange={handleData}
                    className="w-full border border-gray-300 p-2 rounded-md focus:ring-2 focus:ring-indigo-400 transition-all duration-300 ease-in-out"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-semibold text-gray-700">
                    Company Name
                  </label>
                  <input
                    type="text"
                    name="address_line1"
                    value={address.address_line1 || ""}
                    onChange={handleData}
                    className="w-full border border-gray-300 p-2 rounded-md focus:ring-2 focus:ring-indigo-400 transition-all duration-300 ease-in-out"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-semibold text-gray-700">
                    Address
                  </label>
                  <input
                    type="text"
                    name="address_line2"
                    value={address.address_line2 || ""}
                    onChange={handleData}
                    className="w-full border border-gray-300 p-2 rounded-md focus:ring-2 focus:ring-indigo-400 transition-all duration-300 ease-in-out"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-semibold text-gray-700">
                    City
                  </label>
                  <input
                    type="text"
                    name="city"
                    value={address.city || ""}
                    onChange={handleData}
                    className="w-full border border-gray-300 p-2 rounded-md focus:ring-2 focus:ring-indigo-400 transition-all duration-300 ease-in-out"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-semibold text-gray-700">
                    Pincode
                  </label>
                  <input
                    type="number"
                    name="landmark"
                    value={address.landmark || ""}
                    onChange={handleData}
                    className="w-full border border-gray-300 p-2 rounded-md focus:ring-2 focus:ring-indigo-400 transition-all duration-300 ease-in-out"
                  />
                </div>

                <div>
                  <label className="mb-2 block md:text-sm text-xs font-medium text-gray-900">
                    State
                  </label>
                  <select
                    name="state"
                    value={address.state || ""}
                    onChange={handleData}
                    className="block w-full rounded-lg border border-gray-300 p-2.5 md:text-sm text-xs text-gray-900 focus:border-blue-500 focus:ring-blue-500"
                    required
                  >
                    <option value="">Select State</option>
                    <option value="Andhra Pradesh">Andhra Pradesh</option>
                    <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                    <option value="Assam">Assam</option>
                    <option value="Bihar">Bihar</option>
                    <option value="Chhattisgarh">Chhattisgarh</option>
                    <option value="Delhi">Delhi</option>
                    <option value="Goa">Goa</option>
                    <option value="Gujarat">Gujarat</option>
                    <option value="Haryana">Haryana</option>
                    <option value="Himachal Pradesh">Himachal Pradesh</option>
                    <option value="Jharkhand">Jharkhand</option>
                    <option value="Karnataka">Karnataka</option>
                    <option value="Kerala">Kerala</option>
                    <option value="Madhya Pradesh">Madhya Pradesh</option>
                    <option value="Maharashtra">Maharashtra</option>
                    <option value="Manipur">Manipur</option>
                    <option value="Meghalaya">Meghalaya</option>
                    <option value="Mizoram">Mizoram</option>
                    <option value="Nagaland">Nagaland</option>
                    <option value="Odisha">Odisha</option>
                    <option value="Punjab">Punjab</option>
                    <option value="Rajasthan">Rajasthan</option>
                    <option value="Sikkim">Sikkim</option>
                    <option value="Tamil Nadu">Tamil Nadu</option>
                    <option value="Telangana">Telangana</option>
                    <option value="Tripura">Tripura</option>
                    <option value="Uttar Pradesh">Uttar Pradesh</option>
                    <option value="Uttarakhand">Uttarakhand</option>
                    <option value="West Bengal">West Bengal</option>
                  </select>
                </div>
            <input
              type="hidden"
              name="total"
              value={totalamount} // This now directly reflects the value from localStorage
              onChange={handleData}
            />
              </div>
            </div>

            {/* Saved Addresses */}
            <div className="bg-white p-4 rounded-lg shadow-sm space-y-4 border border-gray-200 w-full max-w-md">
              <h2 className="text-base font-semibold text-gray-800 mb-4">
                Saved Addresses
              </h2>

              {/* Display Addresses */}
              <div className="space-y-4">
                {addressesToDisplay.length > 0 ? (
                  addressesToDisplay.map((savedAddress) => (
                    <div
                      key={savedAddress.address_id}
                      className="flex items-center justify-between p-4 rounded-lg border border-gray-200 shadow-sm hover:shadow-md transition-all duration-300 ease-in-out"
                    >
                      <div className="flex items-center">
                        <input
                          type="radio"
                          name="address-selection"
                          value={savedAddress.address_id}
                          onChange={() => handleAddressSelect(savedAddress)}
                          className="h-4 w-4 border-gray-300 bg-white text-indigo-600 focus:ring-2 focus:ring-indigo-600 transition-all duration-300 ease-in-out"
                          checked={
                            address.address_id === savedAddress.address_id
                          }
                        />
                        <div className="ml-2">
                          <p className="text-sm font-semibold text-gray-800">
                            {savedAddress.label}
                          </p>
                          <p className="text-sm text-gray-600">
                            {savedAddress.address_line1},{" "}
                            {savedAddress.address_line2}, {savedAddress.city}
                          </p>
                          <p className="text-xs text-gray-500">
                            {savedAddress.landmark}
                          </p>
                        </div>
                      </div>
                      <button
                        type="button"
                        className="bg-indigo-600 text-white px-3 py-1 rounded-lg text-xs font-semibold shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 transition-all duration-300 ease-in-out"
                        onClick={() => handleAddressDeselect(savedAddress)}
                      >
                        {address.address_id === savedAddress.address_id
                          ? "ADD NEW ADDRESS"
                          : "CHOOSE ADDRESS "}
                      </button>
                    </div>
                  ))
                ) : (
                  <p className="text-gray-600 text-sm">
                    No saved addresses found. Add a new one below.
                  </p>
                )}
              </div>

              {/* Toggle Button */}
              <div className="mt-4 text-center">
                <div
                  onClick={toggleAddressView}
                  className="px-4 py-2 bg-indigo-600 text-white rounded-lg shadow-md hover:bg-indigo-700 transition-all duration-300 ease-in-out"
                >
                  {showAllAddresses
                    ? "Show Default Address Only"
                    : "Show All Addresses"}
                </div>
              </div>
            </div>
          </div>

          {/* Right Column: Payment and Cart Summary */}
          <div className="space-y-6 flex flex-col items-center">
            {/* Payment Section */}
            <div className="bg-white p-4 rounded-lg shadow-sm space-y-4 w-full max-w-md border border-gray-200">
              <h3 className="text-base font-semibold text-gray-800">
                Payment Method
              </h3>
              <div className="flex items-center space-x-3">
                <input
                  id="cod"
                  aria-describedby="cod-text"
                  type="radio"
                  name="payment-method"
                  value="cod"
                  className="h-4 w-4 border-gray-300 bg-white text-indigo-600 focus:ring-2 focus:ring-indigo-600 transition-all duration-300 ease-in-out"
                  checked
                />
                <label
                  htmlFor="cod"
                  className="text-sm text-gray-800 font-medium leading-none"
                >
                  Cash on Delivery
                </label>
              </div>
            </div>

            {/* Cart Summary and Receipt */}
            <div className="bg-white p-4 rounded-lg shadow-sm space-y-4 w-full max-w-md border border-gray-200">
              <CheckoutProductsView data={cart} />
              <CheckoutReceipt cartId={cartId} state={address.state} />
            </div>

            {/* Confirm Order Button */}
            <div className="mt-4 w-full max-w-md">
              <button
                type="submit"
                className="w-full rounded-lg border border-gray-300 bg-indigo-600 px-5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-700 focus:outline-none transition-all duration-300 ease-in-out transform hover:scale-105"
              >
                
                Confirm Order
              </button>
            </div>
          </div>
        </div>
       
      </form>
      <ConfirmationModal isVisible={isModalVisible} onClose={handleModalClose} />
    </section>
  );
}

export default Checkout;
