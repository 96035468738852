import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ApiURl from "../controllers/Api";
import imgLocation from "../controllers/imagePath"; // Importing image location

const SearchBar = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false); // Track dropdown state
  const [noResults, setNoResults] = useState(false); // Track if no results found
  const navigate = useNavigate();
  const searchBarRef = useRef(null); // Create a ref for the search bar container

  // Fetch categories and products with debouncing
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (searchQuery.length > 1) {
        fetchResults(searchQuery);
      } else {
        setCategories([]);
        setProducts([]);
        setDropdownOpen(false); // Close dropdown when search query is cleared
        setNoResults(false); // Reset no results state
      }
    }, 300); // Delay of 300ms

    return () => clearTimeout(timeoutId); // Cleanup on every re-render
  }, [searchQuery]);

  useEffect(() => {
    // Function to close the dropdown if clicked outside the search bar
    const handleClickOutside = (event) => {
      if (searchBarRef.current && !searchBarRef.current.contains(event.target)) {
        setDropdownOpen(false); // Close the dropdown if clicked outside
      }
    };

    // Attach event listener to handle outside click
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const fetchResults = async (query) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.post(`${ApiURl}/Search_categories.php`, {
        query,
      });
      console.log(response.data); // Log the response to inspect the structure
      if (response.data.status === "success") {
        setCategories(response.data.categories); // Set categories
        setProducts(response.data.products); // Set products
        setDropdownOpen(true); // Open dropdown if we have results
        setNoResults(false); // Reset no results flag if data exists
      } else {
        setError(response.data.message || "Unknown error occurred.");
        setCategories([]);
        setProducts([]);
        setDropdownOpen(true); // Keep the dropdown open if no data found
        setNoResults(true); // Set no results flag
      }
    } catch (error) {
      setError("Unable to fetch categories and products. Please try again later.");
      setNoResults(true); // Set no results flag on error
    } finally {
      setIsLoading(false);
    }
  };

  const handleProductSelect = (productId) => {
    console.log("Product ID:", productId);
    if (productId) {
      navigate(`/ProductOverview/${productId}`); // Navigate to the product overview page
    }
    setSearchQuery(""); // Clear search query
    setCategories([]); // Close dropdown
    setProducts([]); // Close dropdown
    setDropdownOpen(false); // Close dropdown
  };

  const handleCategorySelect = (categoryId) => {
    navigate(`/products/${categoryId}`);
    setSearchQuery(""); // Clear search query
    setCategories([]); // Close dropdown
    setProducts([]); // Close dropdown
    setDropdownOpen(false); // Close dropdown
  };

  return (
    <div className="relative w-full max-w-xl" ref={searchBarRef}>
      {/* Input Field */}
      <input
        type="text"
        aria-label="Search categories and products"
        aria-autocomplete="list"
        aria-controls="search-results"
        aria-expanded={dropdownOpen}
        value={searchQuery}
        onChange={(e) => {
          setSearchQuery(e.target.value);
          setDropdownOpen(true); // Ensure dropdown stays open while typing
        }}
        placeholder="Search for categories or products..."
        className="w-full py-2 pl-4 pr-10 text-gray-700 border border-gray-300 rounded-full shadow-sm focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
      />
      <button className="absolute inset-y-0 right-1 flex items-center pr-3">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="#717478"
          width="22"
          height="18"
          viewBox="0 0 30 30"
        >
          <path d="M 13 3 C 7.4889971 3 3 7.4889971 3 13 C 3 18.511003 7.4889971 23 13 23 C 15.396508 23 17.597385 22.148986 19.322266 20.736328 L 25.292969 26.707031 A 1.0001 1.0001 0 1 0 26.707031 25.292969 L 20.736328 19.322266 C 22.148986 17.597385 23 15.396508 23 13 C 23 7.4889971 18.511003 3 13 3 z M 13 5 C 17.430123 5 21 8.5698774 21 13 C 21 17.430123 17.430123 21 13 21 C 8.5698774 21 5 17.430123 5 13 C 5 8.5698774 8.5698774 5 13 5 z"></path>
        </svg>
      </button>

      {/* Results Section */}
      {isLoading ? (
        <ul className="absolute bg-white shadow-md mt-2 rounded-md p-2 z-50">
          {[...Array(5)].map((_, index) => (
            <li key={index} className="animate-pulse flex items-center gap-2 p-2">
              <div className="w-6 h-6 bg-gray-300 rounded-full"></div>
              <div className="h-4 bg-gray-300 rounded w-24"></div>
            </li>
          ))}
        </ul>
      ) : error ? (
        <div className="absolute bg-white shadow-md mt-2 rounded-md p-2 z-50">
          <p className="text-red-500">{error}</p>
        </div>
      ) : dropdownOpen && (categories.length > 0 || products.length > 0) ? (
        <ul
          id="search-results"
          className="absolute bg-white shadow-md mt-2 rounded-md w-full max-h-48 overflow-y-auto z-50"
        >
          {/* Categories */}
          {categories.length > 0 && (
            <>
              <h3 className="px-2 py-1 font-semibold">Categories</h3>
              {categories.map((category) => (
                <li
                  key={category.id}
                  className="p-2 cursor-pointer hover:bg-gray-200 flex items-center"
                  onClick={() => handleCategorySelect(category.id)}
                >
                  {category.sample_image ? (
                    <img
                      src={`${imgLocation}/${category.sample_image}`}
                      alt={category.category_name}
                      className="w-6 h-6 rounded-full"
                    />
                  ) : (
                    <div className="w-6 h-6 bg-gray-300 rounded-full"></div>
                  )}
                  <span className="ml-2">{category.category_name}</span>
                </li>
              ))}
            </>
          )}

          {/* Products */}
          {products.length > 0 && (
            <>
              <h3 className="px-2 py-1 font-semibold">Products</h3>
              {products.map((product) => (
                <li
                  key={product.product_id}
                  className="p-2 cursor-pointer hover:bg-gray-200 flex items-center"
                  onClick={() => handleProductSelect(product.product_id)}
                >
                  {product.product_image ? (
                    <img
                      src={`${imgLocation}/${product.product_image}`}
                      alt={product.product_name}
                      className="w-8 h-12 rounded-full"
                    />
                  ) : (
                    <div className="w-6 h-6 bg-gray-300 rounded-full"></div>
                  )}
                  <span className="ml-2">{product.product_name}</span>
                </li>
              ))}
            </>
          )}
        </ul>
      ) : (
        dropdownOpen && noResults && (
          <div className="absolute bg-white shadow-md mt-2 rounded-md p-2 z-50">
            <p>No results found.</p>
          </div>
        )
      )}
    </div>
  );
};

export default SearchBar;
