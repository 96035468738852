import React, { useEffect, useState, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import $ from "jquery";
import ApiURl from "../controllers/Api";
import imgLocation from "../controllers/imagePath";

const ProductOverview = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState({});
  const [cartMessage, setCartMessage] = useState("");
  const [cartId, setCartId] = useState(localStorage.getItem("cart_id"));
  const [sizes, setSizes] = useState([]);
  const [prices, setPrices] = useState([]);
  const [quantities, setQuantities] = useState([]);
  const [availability, setAvailability] = useState([]);
  const [selectedSizes, setSelectedSizes] = useState([]);
  const [cartTotal, setCartTotal] = useState(0);
  const [wishlistAdded, setWishlistAdded] = useState(false); // Track if product is in wishlist
  const [showPopup, setShowPopup] = useState(false);

  const finalTotal = useMemo(() => {
    const grandTotal = selectedSizes.reduce((acc, size) => {
      const sizeIndex = sizes.indexOf(size);
      const quantity = quantities[sizeIndex] || 1;
      const price = prices[sizeIndex] || 0;
      return acc + quantity * price;
    }, 0);
    return grandTotal + cartTotal;
  }, [selectedSizes, sizes, quantities, prices, cartTotal]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await $.getJSON(
          `${ApiURl}/getProducts.php?product=${id}`
        );
        if (response && response.products && response.products.length > 0) {
          const productData = response.products[0];
          console.log(productData);
          setProduct(productData);

          // Set sizes, prices, and availability based on product data
          const sizes = productData.sizes ? productData.sizes.split(",") : [];
          const prices = productData.prices
            ? productData.prices.split(",").map(Number)
            : [];
          const availability = productData.availability
            ? productData.availability.split(",").map(Number)
            : [];

          setSizes(sizes);
          setPrices(prices);
          setAvailability(availability);

          const initialQuantities = new Array(sizes.length).fill(1);
          setQuantities(initialQuantities);
        } else {
          setCartMessage("No product found.");
        }
      } catch (error) {
        console.error("Error fetching product data:", error);
        setCartMessage("Error fetching product data.");
      }
    };

    const fetchCartTotal = async () => {
      try {
        const response = await $.getJSON(
          `${ApiURl}/getCartItems.php?cart_id=${cartId}`
        );
        if (response && response.data && response.data.length > 0) {
          const totalAmount = response.data.reduce((acc, item) => {
            const price = parseFloat(item.price);
            const quantity = parseInt(item.quantity, 10);
            if (!isNaN(price) && !isNaN(quantity)) {
              return acc + price * quantity;
            }
            return acc;
          }, 0);
          setCartTotal(totalAmount);
        } else {
          setCartTotal(0);
        }
      } catch (error) {
        console.error("Error fetching cart total:", error);
      }
    };

    fetchData();
    fetchCartTotal();
  }, [id, cartId]);

  const handleSizeChange = (e) => {
    const { value, checked } = e.target;
    setSelectedSizes((prevState) =>
      checked
        ? [...prevState, value]
        : prevState.filter((size) => size !== value)
    );
  };

  const handleQuantityChange = (index, value) => {
    if (value === "") {
      const updatedQuantities = [...quantities];
      updatedQuantities[index] = "";
      setQuantities(updatedQuantities);
      return;
    }

    const parsedValue = parseInt(value, 10);
    if (isNaN(parsedValue) || parsedValue <= 0 || parsedValue > 1_000_000) {
      alert("Please enter a valid quantity (1 to 1,000,000)");
      return;
    }

    const updatedQuantities = [...quantities];
    updatedQuantities[index] = parsedValue;
    setQuantities(updatedQuantities);
  };

  const handleAddToCart = async () => {
    try {
      if (!selectedSizes || selectedSizes.length === 0) {
        setCartMessage("Please select at least one size.");
        return;
      }

      const quantitiesToSend = selectedSizes.map((size) => {
        const sizeIndex = sizes.indexOf(size);
        return quantities[sizeIndex] || 1;
      });

      const total_price = selectedSizes.reduce((acc, size, index) => {
        const sizeIndex = sizes.indexOf(size);
        const sizePrice = prices[sizeIndex] || 0;
        const quantity = quantitiesToSend[index];
        return acc + sizePrice * quantity;
      }, 0);

      const response = await $.post(`${ApiURl}/addToCart.php`, {
        product_id: product.product_id,
        cart_id: cartId,
        sizes: JSON.stringify(selectedSizes),
        quantities: JSON.stringify(quantitiesToSend),
        total_price,
      });
      console.log(response);
      if (response.status === "success") {
        setCartMessage("Product added to cart successfully!");
        navigate("/cart");
      } else {
        setCartMessage("Failed to add product to cart.");
      }
    } catch (error) {
      console.error("Error adding to cart:", error);
      setCartMessage("Error adding product to cart.");
    }
  };

  const handleAddToWishlist = async () => {
    try {
      // Ensure that userId and productId are available before sending the request
      const userId = localStorage.getItem("user_id"); // or wherever you're storing the user ID
      if (!userId) {
        alert("Please log in to add to wishlist.");
        return;
      }

      const productId = product.product_id;

      // Perform AJAX request to send the data as JSON
      const response = await $.ajax({
        url: `${ApiURl}/addWishlistItem.php`,
        method: "POST",
        contentType: "application/json", // Ensure the content type is set as JSON
        data: JSON.stringify({
          user_id: userId, // Send the user ID
          product_id: productId, // Send the product ID
        }),
      });

      // Handle response
      if (response.message === "Item added to wishlist successfully") {
        console.log("Item added to wishlist");
        setShowPopup(true); // Show the popup on success
      } else {
        console.error("Error adding to wishlist:", response.message);
      }
    } catch (error) {
      console.error("Error adding to wishlist:", error);
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false); // Close the popup when the close button is clicked
  };

  const calculateGrandTotal = () => {
    return selectedSizes.reduce((acc, size) => {
      const sizeIndex = sizes.indexOf(size);
      const quantity = quantities[sizeIndex] || 1;
      const price = prices[sizeIndex] || 0;
      return acc + quantity * price;
    }, 0);
  };

  return (
    <div className="max-w-7xl mx-auto md:p-8 p-4 bg-gray-100 shadow-lg rounded-lg transition-all duration-500 ease-in-out">
      <div className="hidden md:block p-4 bg-[#C77A2B] text-white font-bold text-center mb-6 rounded-lg">
        Current amount in Cart: ₹{cartTotal}
      </div>
      <div className="flex flex-col md:flex-row gap-6">
        <div className="md:w-1/2 flex justify-center relative">
          <div className="w-full md:max-w-md h-74 bg-gray-200 rounded-lg shadow-lg flex items-center justify-center overflow-hidden">
            <img
              className="h-full w-full object-contain"
              src={`${imgLocation}/${product.img_path}`}
              alt="Product"
            />
            {/* Heart icon for wishlist */}
            <button
              onClick={handleAddToWishlist}
              className={`absolute top-4 right-4 ${
                wishlistAdded ? "text-red-500" : "text-gray-700"
              }`}
            >
              ❤️
            </button>
            {/* Popup/Modal when item is added to wishlist */}
            {showPopup && (
              <div className="popup-overlay">
                <div className="popup-content">
                  <h2>Item Added to Wishlist</h2>
                  <button onClick={handleClosePopup} className="close-button">
                    Close
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="md:w-1/2">
          <p className="text-gray-600 mb-1">{product.category_name}</p>
          <h2 className="text-3xl font-semibold mb-4">{product.name}</h2>
          <div>
            <div className="grid grid-cols-4 text-gray-700 font-bold mb-2 text-center">
              <span className="text-sm">Size</span>
              <span className="text-sm">Price</span>
              <span className="text-sm">Quantity</span>
              <span className="text-sm">Total</span>
            </div>
            {sizes.map((size, index) => (
              <div
                key={index}
                className="grid grid-cols-4 items-center gap-2 mb-2 text-center bg-white p-2 rounded-md shadow-sm"
              >
                <div className="flex flex-col items-center justify-center">
                  <input
                    type="checkbox"
                    id={`size-${index}`}
                    value={size}
                    onChange={handleSizeChange}
                    disabled={availability[index] === 0}
                    className="mb-1"
                  />
                  <label
                    htmlFor={`size-${index}`}
                    className={`${
                      availability[index] === 0
                        ? "text-red-500"
                        : "text-gray-700"
                    } break-words text-xs sm:text-sm lg:text-base`}
                  >
                    {size} {availability[index] === 0 ? "(Unavailable)" : ""}
                  </label>
                </div>
                <span className="text-sm">₹{prices[index]}</span>
                <div className="flex items-center space-x-1 sm:space-x-2">
                  <button
                    type="button"
                    onClick={() =>
                      handleQuantityChange(index, (quantities[index] || 1) - 1)
                    }
                    disabled={
                      quantities[index] <= 1 || availability[index] === 0
                    }
                    className="bg-blue-200 text-blue-800 px-1 sm:px-2 py-1 rounded-md hover:bg-blue-300 text-xs sm:text-sm"
                  >
                    -
                  </button>

                  <input
                    type="number"
                    value={quantities[index] || ""}
                    onChange={(e) =>
                      handleQuantityChange(index, e.target.value)
                    }
                    disabled={availability[index] === 0}
                    className="w-14 sm:w-20 text-center border rounded-md px-2 py-1 text-sm"
                  />

                  <button
                    type="button"
                    onClick={() =>
                      handleQuantityChange(index, (quantities[index] || 1) + 1)
                    }
                    disabled={
                      quantities[index] >= 1_000_000 ||
                      availability[index] === 0
                    }
                    className="bg-blue-200 text-blue-800 px-1 sm:px-2 py-1 rounded-md hover:bg-blue-300 text-xs sm:text-sm"
                  >
                    +
                  </button>
                </div>
                <span className="text-sm">
                  ₹{(quantities[index] || 1) * (prices[index] || 0)}
                </span>
              </div>
            ))}

            <div className="text-right font-semibold mt-6 text-lg">
              Grand Total: ₹{calculateGrandTotal()}
            </div>
          </div>
          <div className="text-right font-semibold mt-4">
            Final Total: ₹{finalTotal}
          </div>
          <div className="mt-6">
            <h3 className="text-gray-700 font-semibold text-lg">
              Product Description:
            </h3>
            <p className="text-gray-600 mt-2">{product.description}</p>
          </div>

          <div className="flex flex-col gap-4 mt-6">
            <button
              onClick={handleAddToCart}
              className="w-full sm:w-auto bg-[#C77A2B] text-white px-4 py-2 rounded-lg text-lg"
            >
              Add to Cart
            </button>
            <div className="lg:hidden p-4 bg-blue-600 text-white font-bold text-center mb-6 rounded-lg">
              Current amount in Cart: ₹{cartTotal}
            </div>
            <div className="text-center text-red-600">{cartMessage}</div>
          </div>
        </div>
      </div>
      <button
        onClick={() => navigate("/")} // Navigate to the previous page
        className="mb-6 mt-4 px-4 py-2 bg-[#C77A2B] text-white rounded-md shadow-md"
      >
        Back to Home page
      </button>
    </div>
  );
};

export default ProductOverview;