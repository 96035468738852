import React, { useState, useEffect } from 'react';
import ApiURl from '../controllers/Api';

const CheckoutReceipt = ({ cartId, state }) => {
  const [cartItems, setCartItems] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState('CASH'); // Default value, can be updated based on user input
  const [isReceiptVisible, setIsReceiptVisible] = useState(false); // State to toggle receipt visibility

  // Fetch the cart data from the PHP API
  const fetchCartData = async () => {
    try {
      const response = await fetch(
        `${ApiURl}/recipetdetail.php?cart_id=${cartId}`
      );
      const data = await response.json();
      console.log(data);
      // Check the response structure in console
      console.log('API Response:', data);

      if (data.status === 'success') {
        // Filter items where selected = 1
        const filteredItems = data.data.filter((item) => item.selected === 1);
        // Update state with filtered data
        setCartItems(filteredItems);
      } else {
        console.error('Error fetching cart data:', data.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Call fetchCartData when the component mounts or when cartId changes
  useEffect(() => {
    fetchCartData();
  }, [cartId]);

  // Helper function to safely format numbers with toFixed
  const formatAmount = (amount) => {
    // Check if the amount is a valid number
    const validAmount = parseFloat(amount);
    if (isNaN(validAmount)) {
      return '0.00';  // Return a default value if the amount is invalid
    }
    return validAmount.toFixed(2);
  };

  // Group cart items by HSN Code
  const groupByHsnCode = (items) => {
    return items.reduce((groups, item) => {
      const hsnCode = item.hsn_code || 'No HSN';  // Group by HSN code, default if none
      if (!groups[hsnCode]) {
        groups[hsnCode] = [];
      }
      groups[hsnCode].push(item);
      return groups;
    }, {});
  };

  // Calculate taxes (SGST, CGST, IGST) for each item based on its HSN code and the selected state
  const getTaxesForState = (item) => {
    if (!state) {
      return { sgst: 0, cgst: 0, igst: 0 }; // No taxes if state is not entered
    }

    // Extract tax percentages directly from the item (received from API)
    const { sgst: sgstPercentage, cgst: cgstPercentage, igst: igstPercentage, total_price } = item;

    if (state === 'Delhi') {
      const sgstAmount = (total_price * sgstPercentage) / 100;
      const cgstAmount = (total_price * cgstPercentage) / 100;
      return { sgst: sgstAmount, cgst: cgstAmount, igst: 0 };
    } else {
      // For inter-state transactions (IGST)
      const igstAmount = (total_price * igstPercentage) / 100;
      return { sgst: 0, cgst: 0, igst: igstAmount };
    }
  };

  // Calculate total for each HSN code group, including GST amounts
  const calculateHsnTotal = (hsnCodeGroup) => {
    return hsnCodeGroup.reduce((total, item) => {
      const { sgst, cgst, igst } = getTaxesForState(item);
      return total + item.total_price + sgst + cgst + igst;
    }, 0);
  };

  const groupedItems = groupByHsnCode(cartItems);

  // Calculate the grand total (sum of all items' prices including taxes for each HSN group)
  const calculateGrandTotal = () => {
    return Object.keys(groupedItems).reduce((total, hsnCode) => {
      const group = groupedItems[hsnCode];
      const hsnTotal = calculateHsnTotal(group); // Get total for the HSN code group including taxes
      return total + hsnTotal; // Add this group total to the overall grand total
    }, 0);
  };

  // Calculate the total without GST
  const calculateTotalWithoutGST = () => {
    return Object.keys(groupedItems).reduce((total, hsnCode) => {
      const group = groupedItems[hsnCode];
      return total + group.reduce((sum, item) => sum + item.total_price, 0);
    }, 0);
  };

  return (
    <div className="mt-6 w-full space-y-6 sm:mt-8 lg:mt-16 lg:max-w-xs xl:max-w-md">
      <div className="space-y-6 rounded-lg border border-gray-200 bg-white p-4 shadow-sm sm:p-6">
        <dt className="text-base text-center border-b pb-4 font-normal text-gray-700">
          Checkout Receipt
        </dt>

        {/* Toggle Button to show/hide full receipt */}
        <div
          onClick={() => setIsReceiptVisible(!isReceiptVisible)}
          className="w-full py-2 px-4 text-center text-white bg-blue-500 rounded-md focus:outline-none cursor-pointer"
        >
          {isReceiptVisible ? 'Hide Full Receipt' : 'Show Full Receipt'}
        </div>

        
        {/* Render the tax information only if the state is selected */}
        {state && isReceiptVisible && (
          <>
            {/* Loop through each HSN Code group */}
            {Object.keys(groupedItems).map((hsnCode) => {
              const group = groupedItems[hsnCode];
              const hsnTotal = calculateHsnTotal(group);

              return (
                <div key={hsnCode} className="space-y-0">
                  <div className="text-base text-gray-600 font-semibold border-b pb-2">
                    HSN Code: {hsnCode}
                  </div>
                  <div className="-my-3 divide-y divide-gray-200">
                    {group.map((item) => {
                      const { sgst, cgst, igst } = getTaxesForState(item);

                      return (
                        <div key={item.product_id} className="space-y-1">
                          <div className="flex justify-between gap-4 py-2">
                            <div className="text-sm font-normal text-gray-500">
                              {item.name}{' '}
                              {item.size && (
                                <span className="text-xs text-gray-600">({item.size})</span>
                              )}
                            </div>
                            <div className="text-sm font-medium text-gray-900">
                              &#x20b9;{formatAmount(item.total_price)}
                            </div>
                          </div>

                          {/* Show SGST, CGST, and IGST for each item */}
                          {sgst > 0 && (
                            <dl className="flex items-center justify-between gap-4 py-3">
                              <dt className="text-sm font-normal text-gray-500">SGST ({item.sgst}%)</dt>
                              <dd className="text-sm font-medium text-gray-900">
                                &#x20b9;{formatAmount(sgst)}
                              </dd>
                            </dl>
                          )}

                          {cgst > 0 && (
                            <dl className="flex items-center justify-between gap-4 py-3">
                              <dt className="text-sm font-normal text-gray-500">CGST ({item.cgst}%)</dt>
                              <dd className="text-sm font-medium text-gray-900">
                                &#x20b9;{formatAmount(cgst)}
                              </dd>
                            </dl>
                          )}

                          {igst > 0 && (
                            <dl className="flex items-center justify-between gap-4 py-3">
                              <dt className="text-sm font-normal text-gray-500">IGST ({item.igst}%)</dt>
                              <dd className="text-sm font-medium text-gray-900">
                                &#x20b9;{formatAmount(igst)}
                              </dd>
                            </dl>
                          )}
                        </div>
                      );
                    })}
                  </div>

                  {/* Total for each HSN group */}
                  <div className="flex justify-between gap-4 py-3 font-semibold text-lg text-gray-900">
                    <span>Total for HSN Code {hsnCode}</span>
                    <span>&#x20b9;{formatAmount(hsnTotal)}</span>
                  </div>
                </div>
              );
            })}
          </>
        )}

        {/* Display Grand Total in a highlighted box */}

        <div className="flex justify-between gap-4 py-3 px-4 bg-indigo-100 border rounded-md">
            <dt className="text-sm font-bold  text-gray-700">Total without GST</dt>
            <dd className="text-sm font-medium text-gray-900">
              &#x20b9;{formatAmount(calculateTotalWithoutGST())}
            </dd>
          </div>

        <div className="flex justify-between gap-4 py-3 px-4 bg-yellow-200 border rounded-md">
          <dt className="text-sm font-bold text-gray-700">Grand Total</dt>
          <dd className="text-sm font-medium text-gray-900">
            &#x20b9;{formatAmount(calculateGrandTotal())}
          </dd>
        </div>
        
        {/* Display Payment Method */}
        <dl className="flex items-center justify-between gap-4 py-3">
          <dt className="text-sm font-normal text-gray-500">Payment method</dt>
          <dd className="text-sm font-medium text-green-500">{paymentMethod}</dd>
        </dl>
      </div>
    </div>
  );
};

export default CheckoutReceipt;
