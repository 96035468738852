import React, { useState, useEffect } from "react";
import ApiURl from "../../controllers/Api";

function AddHsnCode() {
  const [categories, setCategories] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [hsnCode, setHsnCode] = useState("");
  const [igst, setIgst] = useState("");
  const [cgst, setCgst] = useState("");
  const [sgst, setSgst] = useState("");
  const [message, setMessage] = useState("");

  // Fetch main categories
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(`${ApiURl}/getMainCategory.php`);
        const data = await response.json();
        setCategories(data.data);
        console.log(data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent form reload

    // Basic input validation
    if (!categoryId || !hsnCode || !igst || !cgst || !sgst) {
      setMessage("All fields are required.");
      return;
    }

    // Log the data being sent to the backend
    console.log({
      main_category_id: categoryId,
      hsn_code: hsnCode.trim(),
      igst,
      cgst,
      sgst,
    });

    // Submit HSN code and tax rates to the database
    try {
      const response = await fetch(`${ApiURl}/addHsnCode.php`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          main_category_id: categoryId,
          hsn_code: hsnCode.trim(),
          igst,
          cgst,
          sgst,
        }),
      });

      const data = await response.json();
      console.log("Response from backend:", data); // Log response to check for issues

      if (data.status === "success") {
        alert("HSN Code and taxes added successfully");
        setMessage("HSN Code and taxes added successfully!");
        setHsnCode("");
        setIgst("");
        setCgst("");
        setSgst("");
      } else {
        setMessage(data.message || "Error adding HSN Code and taxes.");
      }
    } catch (error) {
      console.error("Error:", error);
      setMessage("An error occurred while adding the HSN Code and taxes.");
    }
  };

  return (
    <div className="bg-gray-100">
      <section className="lg:col-span-2 overflow-x-hidden bg-white border border-gray-900 mx-7">
        <div className="py-8 px-4 mx-auto max-w-2xl">
          <form onSubmit={handleSubmit}>
            <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
              {/* Category Dropdown */}
              <div>
                <label
                  htmlFor="category"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Category
                </label>
                <select
                  id="category"
                  className="border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                  onChange={(e) => setCategoryId(e.target.value)}
                  value={categoryId}
                >
                  <option value="">Select category</option>
                  {categories.map((row) => (
                    <option key={row.id} value={row.id}>
                      {row.category_name}
                    </option>
                  ))}
                </select>
              </div>

              {/* HSN Code Input */}
              <div>
                <label
                  htmlFor="hsnCode"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  HSN Code
                </label>
                <input
                  value={hsnCode}
                  onChange={(e) => setHsnCode(e.target.value)}
                  type="text"
                  name="hsnCode"
                  id="hsnCode"
                  className="border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                  placeholder="Enter HSN Code"
                  required
                />
              </div>

              {/* IGST Input */}
              <div>
                <label
                  htmlFor="igst"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  IGST (%)
                </label>
                <input
                  value={igst}
                  onChange={(e) => setIgst(e.target.value)}
                  type="number"
                  name="igst"
                  id="igst"
                  className="border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                  placeholder="Enter IGST percentage"
                  required
                />
              </div>

              {/* CGST Input */}
              <div>
                <label
                  htmlFor="cgst"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  CGST (%)
                </label>
                <input
                  value={cgst}
                  onChange={(e) => setCgst(e.target.value)}
                  type="number"
                  name="cgst"
                  id="cgst"
                  className="border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                  placeholder="Enter CGST percentage"
                  required
                />
              </div>

              {/* SGST Input */}
              <div>
                <label
                  htmlFor="sgst"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  SGST (%)
                </label>
                <input
                  value={sgst}
                  onChange={(e) => setSgst(e.target.value)}
                  type="number"
                  name="sgst"
                  id="sgst"
                  className="border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                  placeholder="Enter SGST percentage"
                  required
                />
              </div>
            </div>

            {message && (
              <p className="mt-2 text-sm text-red-600">{message}</p>
            )}
            <button
              type="submit"
              className="inline-flex items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center text-white bg-blue-700 rounded-lg focus:ring-4 focus:ring-primary-200 hover:bg-primary-800"
            >
              Add HSN Code & Taxes
            </button>
          </form>
        </div>
      </section>
    </div>
  );
}

export default AddHsnCode;
