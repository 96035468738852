import React from 'react';
import { useNavigate } from 'react-router-dom';
import loginIcon from "../images/login.png"; // Login icon image

const LogoutButton = ({ loggedIn, onLogout }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Remove JWT token and all local storage items
    localStorage.removeItem('token');
    localStorage.removeItem('user_id');
    localStorage.clear();

    // Notify the user
    alert('You have been logged out successfully.');

    // Redirect to login page
    navigate('/login');

    // Trigger onLogout callback to update parent component state
    if (onLogout) {
      onLogout();
    }
  };

  return (
    <div
      onClick={loggedIn ? handleLogout : () => navigate('/login')}  // Click either logs out or redirects to login
      className="flex flex-col justify-center items-center cursor-pointer hover:scale-105 transition duration-300"
    >
      {/* Small Image */}
      <img
        src={loginIcon}
        alt={loggedIn ? "Logout" : "Login"}
        className="w-5 h-5 sm:w-10 sm:h-10 md:w-10 md:h-10"
      />
      {/* Small Text Below Image */}
      <span className="mt-1 text-xs sm:text-sm font-semibold text-white">
        {loggedIn ? "Logout" : "Login"}
      </span>
      {/* Very Small Text Below */}
      <p className="text-[8px] text-gray-100">{loggedIn ? "Click to logout" : "Click to login"}</p>
    </div>
  );
};

export default LogoutButton;
