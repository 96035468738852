import React, { useState, useEffect } from "react";
import { IoEyeOutline } from "react-icons/io5";
import ApiURl from "../../controllers/Api";

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showAllUsers, setShowAllUsers] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null); // State for selected user
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal open state

  // Fetch users from the backend
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${ApiURl}/getUsers.php`);
        const data = await response.json();
        setUsers(data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  // Handle active/inactive toggle
  const handleToggleStatus = async (user) => {
    const newStatus = user.status === 'active' ? 'inactive' : 'active';
    if (window.confirm(`Are you sure you want to mark user "${user.username}" as ${newStatus}?`)) {
      try {
        const response = await fetch(`${ApiURl}/deleteUser.php`, {
          method: "POST", // Use POST to update the status
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: user.user_id,
            status: newStatus,
          }),
        });

        const result = await response.json();
        console.log('Server Response:', result);

        if (result.success) {
          alert(result.message); // Notify the user of success
          // Update the user status in the frontend state
          setUsers(users.map((u) =>
            u.user_id === user.user_id ? { ...u, status: newStatus } : u
          ));
        } else {
          alert(`Error: ${result.message}`);
        }
      } catch (error) {
        console.error("Error toggling user status:", error);
        alert("An error occurred while updating the user status");
      }
    }
  };

  const handleChangeRole = async (user, newRole) => {
    if (window.confirm(`Are you sure you want to make user "${user.username}" a ${newRole}?`)) {
      try {
        const response = await fetch(`${ApiURl}/updateUserRole.php`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: user.user_id,
            role: newRole,
          }),
        });
  
        // Check if the response is ok (status code 200-299)
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const result = await response.json(); // Parse the JSON response
  
        console.log('Server Response:', result);
  
        if (result.success) {
          alert(result.message); // Notify the user of success
          // Update the user role in the frontend state
          setUsers(users.map((u) =>
            u.user_id === user.user_id ? { ...u, role: newRole } : u
          ));
        } else {
          alert(`Error: ${result.message}`);
        }
      } catch (error) {
        console.error("Error changing user role:", error);
        alert("An error occurred while updating the user role");
      }
    }
  };
  
  

  // Handle user pagination (10 users by default or all users)
  const displayedUsers = showAllUsers ? users : users.slice(0, 10);

  // Open the modal with selected user details
  const openModal = (user) => {
    setSelectedUser(user);
    setIsModalOpen(true);
  };

  // Close the modal
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedUser(null);
  };

  return (
    <div className="p-6 bg-gray-50 shadow-lg rounded-md max-w-screen-xl mx-auto">
      <h2 className="text-3xl font-semibold text-center mb-4">User List</h2>
      {loading ? (
        <div className="text-center text-gray-500 transition-opacity duration-500">Loading...</div>
      ) : users.length > 0 ? (
        <>
          <div className="flex justify-between items-center mb-4">
            <button
              onClick={() => setShowAllUsers(!showAllUsers)}
              className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300"
            >
              {showAllUsers ? "Show Less" : "Show All"}
            </button>
          </div>

          {/* Desktop version of the table */}
          <div className="hidden md:block overflow-x-auto max-w-full">
            <table className="min-w-full divide-y divide-gray-200 border-2 border-black">
              <thead className="bg-gray-100">
                <tr>
                  <th className="px-6 py-3 text-left text-sm">User ID</th>
                  <th className="px-6 py-3 text-left text-sm">Name</th>
                  <th className="px-6 py-3 text-left text-sm">Phone Number</th>
                  <th className="px-6 py-3 text-left text-sm">Email</th>
                  <th className="px-6 py-3 text-center text-sm">Actions</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {displayedUsers.map((user) => (
                  <tr key={user.user_id} className="transition-all hover:bg-gray-100">
                    <td className="px-6 py-4 border">{user.user_id}</td>
                    <td className="px-6 py-4 border">{user.username}</td>
                    <td className="px-6 py-4 border">{user.phone}</td>
                    <td className="px-6 py-4 border">{user.email}</td>
                    <td className="px-6 py-4 text-center border">
                      <div className="flex justify-around">
                        {/* Change View Detail icon to button */}
                        <button
                          onClick={() => openModal(user)} // Open modal on click
                          className="px-3 py-1 text-xs md:text-sm text-white bg-blue-500 border border-blue-500 rounded-md hover:bg-blue-500 hover:text-white"
                        >
                          View Details
                        </button>
                        <button
                          onClick={() => handleToggleStatus(user)} // Toggle user status
                          className={`px-4 py-2 rounded-md ${user.status === 'active' ? 'bg-green-500 text-white' : 'bg-red-500 text-white'} hover:bg-opacity-80`}
                        >
                          {user.status === 'active' ? 'Deactivate' : 'Activate'}
                        </button>
                        {/* Dropdown or button to change role */}
                        <button
                          onClick={() => handleChangeRole(user, user.role === 'admin' ? 'user' : 'admin')}
                          className="px-4 py-2 rounded-md bg-yellow-500 text-white hover:bg-opacity-80"
                        >
                          {user.role === 'admin' ? 'Make User' : 'Make Admin'}
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Mobile version of the table */}
          <div className="md:hidden overflow-x-auto max-w-full">
  <table className="min-w-full divide-y divide-gray-200 border-2 border-black">
    <thead className="bg-gray-100">
      <tr>
        <th className="px-3 py-2 text-left text-sm">Serio No</th>
        <th className="px-3 py-2 text-left text-sm">User Details</th>
      </tr>
    </thead>
    <tbody className="bg-white divide-y divide-gray-200">
      {displayedUsers.map((user) => (
        <tr key={user.user_id} className="transition-all hover:bg-gray-100">
          <td className="px-3 py-2 border text-sm">{user.user_id}</td>
          <td className="px-3 py-4 border text-sm">
            <div>
              <p className="text-xs">{user.username}</p>
              <p className="text-xs">{user.email}</p>
              <p className="text-xs">{user.phone}</p>
              {/* Action Buttons below the user details */}
              <div className="mt-2 flex space-x-1">
                <button
                  onClick={() => openModal(user)} // Open modal on click
                  className="px-1 py-0.5 text-[10px] text-white bg-blue-500 border border-blue-500 rounded-md hover:bg-blue-500 hover:text-white"
                >
                  View Details
                </button>
                <button
                  onClick={() => handleToggleStatus(user)} // Toggle user status
                  className={`px-1 py-0.5 rounded-md ${user.status === 'active' ? 'bg-green-500 text-white' : 'bg-red-500 text-white'} hover:bg-opacity-80 text-[10px]`}
                >
                  {user.status === 'active' ? 'Deactivate' : 'Activate'}
                </button>
                <button
                  onClick={() => handleChangeRole(user, user.role === 'admin' ? 'user' : 'admin')}
                  className="px-1 py-0.5 rounded-md bg-yellow-500 text-white hover:bg-opacity-80 text-[10px]"
                >
                  {user.role === 'admin' ? 'Make User' : 'Make Admin'}
                </button>
              </div>
            </div>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
</div>



          {/* Modal to display user details */}
          {isModalOpen && selectedUser && (
            <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
              <div className="bg-white p-6 rounded-lg shadow-lg w-96">
                <h3 className="text-sm font-semibold mb-4">User Details</h3>
                <div className="space-y-2">
                  <p><strong>User ID:</strong> {selectedUser.user_id}</p>
                  <p><strong>Name:</strong> {selectedUser.username}</p>
                  <p><strong>Phone Number:</strong> {selectedUser.phone}</p>
                  <p><strong>Email:</strong> {selectedUser.email}</p>
                </div>
                <div className="mt-4 flex justify-end">
                  <button
                    onClick={closeModal} // Close the modal
                    className="px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="text-center text-gray-500">No users found.</div>
      )}
    </div>
  );
};

export default UserList;